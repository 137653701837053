import React from "react";

import { SInputNumberWrapper } from "./SInputNumber.style";
import { IconButton, Typography } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";

export interface SInputNumberProps {
  className?: string;
  value?: number;
  name?: string;
  step?: number;
  min?: number;
  onChange?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    value: number
  ) => void;
}

export default function SInputNumber({
  className = "",
  value = 1,
  step = 1,
  min = 0,
  onChange,
}: SInputNumberProps) {
  const handleMinusClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const computedValue = Number(value) - step;

    if (computedValue >= min && onChange) {
      onChange(event, computedValue);
    }
  };

  const handlePlusClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const computedValue = Number(value) + step;

    if (onChange) {
      onChange(event, computedValue);
    }
  };

  return (
    <SInputNumberWrapper className={`SInputNumber ${className}`}>
      <div className="SInputNumber__content">
        <IconButton
          onClick={handleMinusClick}
          sx={{
            borderStyle: "solid",
            borderWidth: (theme) => theme.rem(1),
            borderColor: (theme) => theme.colorBorder,
            borderRadius: "50%",
          }}
        >
          <RemoveIcon
            sx={[
              (theme) => ({
                color: theme.colorBlack,
                width: "16px",
                height: "16px",
              }),
            ]}
          />
        </IconButton>
        <Typography
          sx={[
            (theme) => ({
              color: theme.colorSecondary,
              marginX: 2,
            }),
          ]}
        >
          {value}
        </Typography>
        <IconButton
          onClick={handlePlusClick}
          sx={{
            borderStyle: "solid",
            borderWidth: (theme) => theme.rem(1),
            borderColor: (theme) => theme.colorBorder,
            borderRadius: "50%",
          }}
        >
          <AddIcon
            sx={[
              (theme) => ({
                color: theme.colorBlack,
                width: "16px",
                height: "16px",
              }),
            ]}
          />
        </IconButton>
      </div>
    </SInputNumberWrapper>
  );
}
