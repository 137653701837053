import React, { useEffect, useMemo, useState } from "react";
import { StepFormContainer } from "../StepFormContainer";
import { StepFC } from "./typings";
import { useNavigate } from "react-router";
import { Trans, useTranslation } from "react-i18next";
import { DataStore, Storage } from "aws-amplify";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  LinearProgress,
  Snackbar,
  Typography,
  linearProgressClasses,
  useTheme,
} from "@mui/material";
import { green } from "@mui/material/colors";

import WarningIcon from "@mui/icons-material/Warning";
import { photo } from "../../data/photoStore";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { QueueState } from "../../data/queue";
import { LazyPhoto, Visit } from "../../models";
import { notUndefined } from "../../utils";
import DeleteIcon from "@mui/icons-material/Delete";

const Photo: React.FC<{
  filename: string;
  folder: string;
  blob?: Blob;
}> = ({ filename, folder, blob }) => {
  const [url, setUrl] = useState<string>();
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (blob) {
      setUrl(URL.createObjectURL(blob));
    } else {
      const request = Storage.get(`${folder}/${decodeURIComponent(filename)}`, {
        level: "public",
      }).then(setUrl, (error) => {
        if (Storage.isCancelError(error)) {
          console.log("CANCELED", filename);
        }
      });

      return () => Storage.cancel(request);
    }
  }, [folder, filename, blob]);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    setLoading(true);

    Storage.remove(`${folder}/${decodeURIComponent(filename)}`, {
      level: "public",
    });
  };

  return (
    <>
      <ImageListItemBar
        sx={{
          background:
            "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
            "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
          borderRadius: 3,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
        }}
        position="top"
        actionIcon={
          <IconButton
            sx={{ color: "white" }}
            aria-label={`delete ${filename}`}
            onClick={handleClick}
          >
            {loading ? (
              <CircularProgress size={theme.spacing(3)} color="inherit" />
            ) : (
              <DeleteIcon />
            )}
          </IconButton>
        }
        actionPosition="right"
      />
      <Box
        component="img"
        src={url}
        sx={{ width: "100%", borderRadius: 3, border: "solid 1px #dee2e6" }}
        alt={filename}
        loading="lazy"
      />
    </>
  );
};

const usePhotoReduce = (photos: LazyPhoto[]) =>
  useMemo(
    () =>
      Object.entries(
        photos.reduce(
          (acc, item) => ({
            ...acc,
            [item.key]: acc[item.key]
              ? {
                  sequencerLength: Math.max(
                    acc[item.key].sequencerLength,
                    item.sequencer.length
                  ),
                  items: [...acc[item.key].items, item],
                }
              : {
                  sequencerLength: item.sequencer.length,
                  items: [item],
                },
          }),
          {} as Record<string, { sequencerLength: number; items: LazyPhoto[] }>
        )
      )
        .map(([key, data]) =>
          data.items
            .map(({ event, sequencer }) => ({
              event,
              /**
               * @see https://docs.aws.amazon.com/AmazonS3/latest/userguide/notification-content-structure.html
               */
              sequencer: sequencer.padEnd(data.sequencerLength, "0"),
            }))
            .sort((left, right) => (left.sequencer > right.sequencer ? 1 : -1))
            .reduce(
              (_result, { event }) => event === "ObjectCreated",
              false
            ) === false
            ? undefined
            : key
        )
        .filter(notUndefined),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [photos.length]
  );

export const Photos: StepFC = ({ visit, previous, next, current }) => {
  const theme = useTheme();
  const [photoStore, setPhotoStore] = useState<QueueState>();
  const photosFilenames = usePhotoReduce(visit.photos);
  const navigate = useNavigate();
  const { t } = useTranslation("translation", { keyPrefix: "photo" });
  const [uploadStatus, setUploadStatus] = useState({
    loading: photoStore?.work.status === "UPLOADING",
    progress:
      photoStore?.work.status === "UPLOADING" ? photoStore.work.progress : 0,
  });
  const [locallyStoredPhotos, setLocallyStoredPhotos] = useState<
    Array<{
      name: string;
      blob: Blob;
    }>
  >([]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    return DataStore.save(
      Visit.copyOf(visit, (draft) => {
        if (!draft.updated) {
          draft.updated = {};
        }

        if (!draft.updated.steps) {
          draft.updated.steps = {};
        }

        draft.updated.steps.photos = true;
      })
    ).then(() =>
      navigate(`?step=${next}`, {
        relative: "path",
      })
    );
  };

  const queue = photoStore?.queue;
  const instructions = ["pictureFormat", "nobody", "tidyApartment", "bedMade"];

  useEffect(() => photo.subscribe(setPhotoStore), [visit.id]);

  useEffect(() => {
    if (photoStore?.work.status === "UPLOADING") {
      setUploadStatus({
        loading: true,
        progress: photoStore.work.progress * 100,
      });
    }

    if (
      photoStore?.work.status === "UPLOADING" &&
      photoStore.work.progress === 1 &&
      queue?.length === 0
    ) {
      setTimeout(() => {
        setUploadStatus((prevState) => ({
          ...prevState,
          loading: false,
        }));
      }, 2000);
    }
  }, [photoStore, queue?.length]);

  const mergedPhotos: Array<{ name: string; blob?: Blob }> = [
    ...locallyStoredPhotos,
    ...photosFilenames.map((filename) => ({ name: filename })),
  ].reduce((acc, photo) => {
    if (!acc.find((p) => p.name === photo.name)) {
      acc.push(photo);
    }
    return acc;
  }, [] as Array<{ name: string; blob?: Blob }>);

  return (
    <StepFormContainer
      previous={previous}
      onSubmit={handleSubmit}
      title={t("title")}
      disabled={mergedPhotos.length < 10}
      visit={visit}
    >
      <Alert
        severity="warning"
        icon={<WarningIcon />}
        sx={{ "& .MuiAlert-icon": { alignItems: "center" }, borderRadius: 3 }}
      >
        <AlertTitle sx={{ fontWeight: 600, fontSize: "0.9rem" }}>
          {t("alert.title")}
        </AlertTitle>
        <Typography variant="caption" sx={{ letterSpacing: 0, lineHeight: 0 }}>
          {t("alert.subtitle")}
        </Typography>
      </Alert>
      <Box component="div">
        <Card variant="outlined" sx={{ marginY: 2, borderRadius: 3 }}>
          <CardContent
            sx={{
              "&:last-child": {
                paddingBottom: 2,
              },
            }}
          >
            <Trans components={{ strong: <strong /> }}>
              {t("card.content")}
            </Trans>
            <Box component="ul" mb={0}>
              {instructions.map((element) => (
                <Box key={element} component="li">
                  <Typography variant="body2">
                    {t(`card.instructions.${element}`)}
                  </Typography>
                </Box>
              ))}
            </Box>
          </CardContent>
        </Card>
      </Box>
      <Box
        sx={{
          position: "fixed",
          bottom: theme.spacing(11.5),
          zIndex: 1,
          display: current !== ("null" as any) ? "flex" : "none",
          justifyContent: "center",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <Button
          sx={{
            borderRadius: 3,
            background: "linear-gradient(90deg, #2D3282 0%, #EF486B 127.37%)",
            color: "white",
          }}
          component="label"
        >
          <AddAPhotoIcon />
          <input
            type="file"
            accept="image/*"
            multiple
            hidden
            onChange={(event) => {
              if (event.target.files && event.target.files.length > 0) {
                photo
                  .storeFiles(
                    Array.from(event.target.files).map((blob, index) => {
                      const fileExtension = blob.name.split(".").pop();

                      return {
                        folder: visit.id,
                        blob,
                        name: `${index}_` + Date.now() + `.${fileExtension}`,
                      };
                    })
                  )
                  .then(
                    (e) => {
                      console.log("STORED", e);
                      setLocallyStoredPhotos([
                        ...locallyStoredPhotos,
                        ...e.map((el) => ({
                          name: el.name,
                          blob: el.blob,
                        })),
                      ]);
                    },
                    (err) => console.error("STORED FAILED", err)
                  );
              }
            }}
          />
        </Button>
      </Box>
      <div>
        <ImageList
          sx={{ width: 1, marginBottom: 8, overflowY: "unset" }}
          cols={2}
          gap={10}
        >
          {mergedPhotos.map(({ name, blob }) => (
            <ImageListItem key={name} sx={{ borderRadius: 5 }}>
              <Photo filename={name} folder={visit.id} blob={blob} />
            </ImageListItem>
          ))}
        </ImageList>
      </div>
      <Snackbar
        open={uploadStatus.loading}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        sx={{ left: 0, right: 0, top: 0 }}
      >
        <Box sx={{ width: "100%" }}>
          <LinearProgress
            sx={{
              height: theme.spacing(1),
              backgroundColor: "unset",
              [`& .${linearProgressClasses.bar}`]: {
                background:
                  uploadStatus.progress === 100
                    ? green[500]
                    : "linear-gradient(90deg, #2D3282 0%, #EF486B 127.37%)",
              },
            }}
            variant="determinate"
            value={uploadStatus.progress}
          />
        </Box>
      </Snackbar>
    </StepFormContainer>
  );
};
