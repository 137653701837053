import React from "react";
import {
  Box,
  Typography,
  CardActionArea,
  CardContent,
  Card,
  LinearProgress,
  linearProgressClasses,
  styled,
  Link,
} from "@mui/material";

import moment from "moment-timezone";

import { QueueState } from "../data/queue";
import { getStep, stepOrder } from "./steps/steps";
import { LazyVisit } from "../models";
import { useNavigate } from "react-router-dom";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { useTranslation } from "react-i18next";

const COLORS = {
  warning: {
    primary: "#FFFFFF",
    secondary: "#FF7447",
    tertiary: "#EACEC5",
  },
  inProgress: {
    primary: "#FFFFFF",
    secondary: "#2d3282",
    tertiary: "#E8EFF7",
  },
  default: {
    primary: "#2d3282",
    secondary: "#FFFFFF",
    tertiary: "#E8EFF7",
  },
} as const;

type EnumColors = keyof typeof COLORS;

const BorderLinearProgress = styled(LinearProgress)<{
  customcolor: EnumColors;
}>(({ customcolor }) => ({
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: (COLORS as any)[customcolor].tertiary,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: (COLORS as any)[customcolor].primary,
  },
}));

type VisitCardProps = {
  visit: LazyVisit;
  queue: QueueState["queue"];
};

export const VisitCard = ({ visit, queue }: VisitCardProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const step = getStep(visit);

  const progress = (stepOrder.indexOf(step) / (stepOrder.length - 1)) * 100;
  const visitIsSync = queue.every((q) => !(q.folder === visit.id));
  let cardColor: EnumColors = "warning";

  const defaultValues = {
    firstname:
      visit.updated?.client?.firstname ?? visit.origin?.client?.firstname,
    lastname:
      visit.updated?.client?.lastname?.toLocaleUpperCase() ??
      visit.origin?.client?.lastname?.toLocaleUpperCase(),
    address:
      visit.updated?.apartment?.address ?? visit.origin?.apartment?.address,
    postalCode:
      visit.updated?.apartment?.postalCode ??
      visit.origin?.apartment?.postalCode,
    city: visit.updated?.apartment?.city ?? visit.origin?.apartment?.city,
  };

  if (!visit.complete && visitIsSync) {
    cardColor = progress % 100 === 0 ? "default" : "inProgress";
  }

  const handleNavigate = (url: string) => {
    navigate(url);
  };

  const ICON = {
    warning: <WarningAmberIcon />,
    inProgress: <PauseCircleOutlineIcon />,
    default: <PhoneEnabledIcon />,
  } as const;

  const fullAddress = `${defaultValues.address} ${defaultValues.postalCode} ${defaultValues.city}`;

  const handleMapsRedirect = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    window.open(
      `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
        fullAddress
      )}`,
      "_blank"
    );
  };

  return (
    <Box>
      <Typography color="text.secondary">
        {moment(visit.scheduledDate).format("H:mm")}
      </Typography>
      <Card
        variant="outlined"
        sx={{
          borderRadius: 2,
          marginBottom: 2,
          color: COLORS[cardColor].primary,
          backgroundColor: COLORS[cardColor].secondary,
        }}
      >
        <CardActionArea
          onClick={() => handleNavigate(`${visit.id}?step=${step}`)}
        >
          <CardContent>
            <Box width={1} display="flex" justifyContent="space-between">
              <Typography variant="h6">
                {defaultValues.firstname} {defaultValues.lastname}
              </Typography>
              <Box
                component={Link}
                sx={{
                  width: "2em",
                  height: "2em",
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  color: COLORS[cardColor].primary,
                  textDecorationColor: COLORS[cardColor].primary,
                }}
                href={`tel:${visit.origin?.client.phoneNumber}`}
                onClick={(e) => e.stopPropagation()}
              >
                {ICON[cardColor]}
              </Box>
            </Box>
            <Link
              variant="caption"
              target="_blank"
              onClick={handleMapsRedirect}
              sx={{
                color: COLORS[cardColor].primary,
                textDecorationColor: COLORS[cardColor].primary,
              }}
            >
              {fullAddress}
            </Link>
            {(visit.complete || !visitIsSync) && (
              <Typography
                component="p"
                variant="caption"
                lineHeight={1}
                letterSpacing="normal"
                fontSize="11px"
              >
                {t("warning")}
              </Typography>
            )}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <Typography variant="body2" mb={0.5}>
                {`${Math.round(progress)}%`}
              </Typography>
              <Box sx={{ width: 1 }}>
                <BorderLinearProgress
                  customcolor={cardColor}
                  variant="determinate"
                  value={progress}
                />
              </Box>
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  );
};
