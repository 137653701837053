import { styled } from "@mui/material";

export const RoomWrapper = styled("div")`
  .Room {
    &__header {
      display: flex;
      align-items: center;
      cursor: pointer;

      &__resume {
        flex: 1;
        width: auto;
        margin-left: 1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &__collapse {
        width: 1rem;
        height: 1rem;
        margin-left: auto;
        color: ${({ theme }) => theme.colorPrimary};
        transition: transform 0.1s linear;

        &--extend {
          transform: rotate(90deg);
        }
      }

      &__title {
        color: ${({ theme }) => theme.colorPrimary};
      }
    }

    &__items {
      position: relative;
      display: none;
      grid-template-columns: 1fr;
      gap: auto;

      &--extended {
        display: grid;
      }

      @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
      }

      .RoomItemInput {
        width: 100%;

        @media (min-width: 768px) {
          width: fit-content;

          &:nth-of-type(even) {
            justify-self: end;
          }
        }
      }
    }
  }
`;
