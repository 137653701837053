import React from "react";
import { useTranslation } from "react-i18next";

import KeywordBadge from "../KeywordBadge/KeywordBadge";

import { Typography } from "@mui/material";
import { KeywordCategoryWrapper } from "./KeywordCategory.style";

export type KeywordType = {
  id: number;
  code: string;
  type: string;
};

export type KeywordsType = Array<KeywordType>;
export type KeywordsCategory =
  | "building"
  | "neighborhood"
  | "apartment"
  | "surrounding";

const apartmentString = "apartment";
const buildingString = "building";
const neighboroodString = "neighborhood";
const surroundingString = "surrounding";

export const keywordCategoryMinMax = {
  [apartmentString]: { min: 3, max: 4 },
  [buildingString]: { min: 1, max: 1 },
  [neighboroodString]: { min: 1, max: 1 },
  [surroundingString]: { min: 0, max: 10 },
};

export interface KeywordCategoryProps {
  editable: boolean;
  category: KeywordsCategory;
  selected: Array<number>;
  keywordByType: {
    [key: string]: KeywordsType;
  };
  min: number;
  max?: number;
  onClick: CallableFunction;
}

export default function KeywordCategory({
  editable,
  category,
  selected,
  keywordByType,
  min,
  max,
  onClick,
}: KeywordCategoryProps) {
  const { t } = useTranslation();

  const getNbKeywordSelectedByCategory = () => {
    const keywordsFromCategoryIds = getKeywords()
      .filter((keyword) => keyword.type === category)
      .map((keyword) => keyword.id);

    return selected.reduce(
      (acc, id) => acc + (keywordsFromCategoryIds.includes(id) ? 1 : 0),
      0
    );
  };

  const canSelect = () => {
    const nbSelected = getNbKeywordSelectedByCategory();

    return !max || nbSelected < max;
  };

  const handleClick = (keyword: KeywordType) => {
    if (canSelect() || selected.includes(keyword.id)) return onClick(keyword);

    return () => {};
  };

  const getKeywords = () => {
    return keywordByType[category] || [];
  };

  const keywords = getKeywords();
  const nbSelected = getNbKeywordSelectedByCategory();

  const validationCondition = (
    <span
      className={`KeywordCategory__min-max ${
        min && nbSelected < min ? "KeywordCategory__min-max--error" : ""
      }`}
    >
      {min
        ? `${
            max && max !== min
              ? t("common.quantity.between", { min, max })
              : t("common.quantity.minimal", {
                  strength: min,
                })
          }`
        : ""}
    </span>
  );

  return (
    <KeywordCategoryWrapper>
      <div
        className={`KeywordCategory__header ${
          editable ? `KeywordCategory__header--edit` : ""
        }`}
      >
        <Typography sx={{ fontWeight: 600 }} color="text.secondary">
          {t(`tags.keyword.${category}`)}
        </Typography>
        <Typography color="text.secondary">{validationCondition}</Typography>
      </div>
      <div className="KeywordCategory__keyword-list">
        {keywords.map((keyword) => (
          <KeywordBadge
            key={keyword.id}
            editable={
              (canSelect() || selected.includes(keyword.id)) && editable
            }
            keyword={keyword}
            onClick={handleClick}
            selected={selected.includes(keyword.id)}
          />
        ))}
      </div>
    </KeywordCategoryWrapper>
  );
}
