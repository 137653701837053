import React from "react";
import { EquipmentIconWrapper } from "./EquipmentIcon.style";

export type EquipmentIconColor =
  | "default"
  | "black"
  | "gray"
  | "white"
  | "blue";

export interface EquipmentIconProps {
  name: string;
  color?: EquipmentIconColor;
}

export const EquipmentIcon = ({
  name,
  color = "default",
}: EquipmentIconProps) => {
  const iconColor = color ? `household_items--${color}` : "";

  return (
    <EquipmentIconWrapper className={`household_items-${name} ${iconColor}`} />
  );
};

export default EquipmentIcon;
