import { memo, useState } from "react";
import { LazyVisit } from "../models";

import { Box, Chip, Divider, Typography } from "@mui/material";
import { photo } from "../data/photoStore";
import { QueueState } from "../data/queue";
import { VisitCard } from "./VisitCard";
import moment from "moment-timezone";

export const VisitList: React.FC<{ visits: LazyVisit[] }> = memo(
  ({ visits }) => {
    const [queue, setQueue] = useState<QueueState["queue"]>([]);

    const handlePhotoStore = (queueState: QueueState) => {
      setQueue(queueState.queue);
    };

    photo.subscribe(handlePhotoStore);

    const sortedVisits = visits.sort(
      ({ scheduledDate: a }, { scheduledDate: b }) => {
        if (moment(a).isSame(b, "day")) {
          return moment(a).isBefore(b) ? -1 : 1;
        }

        return moment(a).isBefore(b) ? -1 : 1;
      }
    );

    const groupedVisits: { [date: string]: LazyVisit[] } = {};
    sortedVisits.forEach((visit) => {
      if (!groupedVisits[moment(visit.scheduledDate).format("DD/MM/YYYY")]) {
        groupedVisits[moment(visit.scheduledDate).format("DD/MM/YYYY")] = [];
      }
      groupedVisits[moment(visit.scheduledDate).format("DD/MM/YYYY")].push(
        visit
      );
    });

    const generateDate = (date: string) => {
      if (moment(date, "DD/MM/YYYY").isSame(moment(), "day"))
        return "Aujourd'hui";

      if (moment(date, "DD/MM/YYYY").isSame(moment().subtract(1, "day"), "day"))
        return "Hier";

      if (moment(date, "DD/MM/YYYY").isSame(moment().add(1, "day"), "day"))
        return "Demain";

      return date;
    };

    const renderList = (groupedVisits: { [date: string]: LazyVisit[] }) => {
      return (
        <div>
          {Object.entries(groupedVisits).map(([date, visits]) => (
            <div key={date}>
              <Divider>
                <Chip
                  label={generateDate(date)}
                  size="small"
                  sx={{
                    color: (theme) => theme.colorLight,
                    backgroundColor: (theme) => theme.colorSecondary,
                  }}
                />
              </Divider>
              {visits.map((visit) => (
                <VisitCard visit={visit} queue={queue} key={visit.id} />
              ))}
            </div>
          ))}
        </div>
      );
    };

    return (
      <Box
        sx={{
          overflow: "auto",
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {sortedVisits.length ? (
          renderList(groupedVisits)
        ) : (
          <Box display="flex" justifyContent="center">
            <Typography color="textSecondary">Aucune visite</Typography>
          </Box>
        )}
      </Box>
    );
  }
);
