export const schema = {
    "models": {
        "Visit": {
            "name": "Visit",
            "fields": {
                "id": {
                    "name": "id",
                    "isArray": false,
                    "type": "ID",
                    "isRequired": true,
                    "attributes": []
                },
                "scheduledDate": {
                    "name": "scheduledDate",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": []
                },
                "agents": {
                    "name": "agents",
                    "isArray": true,
                    "type": "AWSEmail",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "origin": {
                    "name": "origin",
                    "isArray": false,
                    "type": {
                        "nonModel": "OriginData"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "updated": {
                    "name": "updated",
                    "isArray": false,
                    "type": {
                        "nonModel": "UpdatedData"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "photos": {
                    "name": "photos",
                    "isArray": true,
                    "type": {
                        "nonModel": "Photo"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": false
                },
                "prospectId": {
                    "name": "prospectId",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "complete": {
                    "name": "complete",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "createdAt": {
                    "name": "createdAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                },
                "updatedAt": {
                    "name": "updatedAt",
                    "isArray": false,
                    "type": "AWSDateTime",
                    "isRequired": false,
                    "attributes": [],
                    "isReadOnly": true
                }
            },
            "syncable": true,
            "pluralName": "Visits",
            "attributes": [
                {
                    "type": "model",
                    "properties": {}
                },
                {
                    "type": "auth",
                    "properties": {
                        "rules": [
                            {
                                "allow": "public",
                                "operations": [
                                    "create",
                                    "update",
                                    "delete",
                                    "read"
                                ]
                            }
                        ]
                    }
                }
            ]
        }
    },
    "enums": {
        "CategoryEnum": {
            "name": "CategoryEnum",
            "values": [
                "STUDIO",
                "T1",
                "T1_BIS",
                "T2",
                "T3",
                "T4",
                "T5",
                "T6",
                "T7",
                "OTHERS"
            ]
        },
        "OwnershipEnum": {
            "name": "OwnershipEnum",
            "values": [
                "TENANT",
                "OWNER"
            ]
        },
        "GenderEnum": {
            "name": "GenderEnum",
            "values": [
                "MALE",
                "FEMALE"
            ]
        },
        "HousingEnum": {
            "name": "HousingEnum",
            "values": [
                "HOUSE",
                "APARTMENT"
            ]
        },
        "PrerequisiteStatusEnum": {
            "name": "PrerequisiteStatusEnum",
            "values": [
                "CONFORM",
                "PENDING",
                "REFUSED"
            ]
        }
    },
    "nonModels": {
        "OriginData": {
            "name": "OriginData",
            "fields": {
                "apartment": {
                    "name": "apartment",
                    "isArray": false,
                    "type": {
                        "nonModel": "ApartmentData"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "client": {
                    "name": "client",
                    "isArray": false,
                    "type": {
                        "nonModel": "ClientData"
                    },
                    "isRequired": true,
                    "attributes": []
                },
                "leaving": {
                    "name": "leaving",
                    "isArray": false,
                    "type": {
                        "nonModel": "LeavingData"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "UpdatedData": {
            "name": "UpdatedData",
            "fields": {
                "steps": {
                    "name": "steps",
                    "isArray": false,
                    "type": {
                        "nonModel": "Steps"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "apartment": {
                    "name": "apartment",
                    "isArray": false,
                    "type": {
                        "nonModel": "ApartmentData"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "client": {
                    "name": "client",
                    "isArray": false,
                    "type": {
                        "nonModel": "ClientData"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "leaving": {
                    "name": "leaving",
                    "isArray": false,
                    "type": {
                        "nonModel": "LeavingData"
                    },
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ClientData": {
            "name": "ClientData",
            "fields": {
                "firstname": {
                    "name": "firstname",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "lastname": {
                    "name": "lastname",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "birthDate": {
                    "name": "birthDate",
                    "isArray": false,
                    "type": "AWSDate",
                    "isRequired": false,
                    "attributes": []
                },
                "phoneNumber": {
                    "name": "phoneNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "email": {
                    "name": "email",
                    "isArray": false,
                    "type": "AWSEmail",
                    "isRequired": false,
                    "attributes": []
                },
                "gender": {
                    "name": "gender",
                    "isArray": false,
                    "type": {
                        "enum": "GenderEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "birthTown": {
                    "name": "birthTown",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "LeavingData": {
            "name": "LeavingData",
            "fields": {
                "rent": {
                    "name": "rent",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "monthlyCharges": {
                    "name": "monthlyCharges",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "allowance": {
                    "name": "allowance",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "ApartmentData": {
            "name": "ApartmentData",
            "fields": {
                "address": {
                    "name": "address",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "postalCode": {
                    "name": "postalCode",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "city": {
                    "name": "city",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "housingType": {
                    "name": "housingType",
                    "isArray": false,
                    "type": {
                        "enum": "HousingEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "area": {
                    "name": "area",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "floor": {
                    "name": "floor",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "door": {
                    "name": "door",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "capacity": {
                    "name": "capacity",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "category": {
                    "name": "category",
                    "isArray": false,
                    "type": {
                        "enum": "CategoryEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "rooms": {
                    "name": "rooms",
                    "isArray": true,
                    "type": {
                        "nonModel": "Room"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "equipments": {
                    "name": "equipments",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "keywords": {
                    "name": "keywords",
                    "isArray": true,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "rentalAgency": {
                    "name": "rentalAgency",
                    "isArray": false,
                    "type": {
                        "nonModel": "RentalAgency"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "electricitySupplyLocalization": {
                    "name": "electricitySupplyLocalization",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "waterSupplyLocalization": {
                    "name": "waterSupplyLocalization",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "cleaningDirective": {
                    "name": "cleaningDirective",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "wasteInstructions": {
                    "name": "wasteInstructions",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "wifi": {
                    "name": "wifi",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "wifiInstructions": {
                    "name": "wifiInstructions",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "wifiPassword": {
                    "name": "wifiPassword",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "accesses": {
                    "name": "accesses",
                    "isArray": true,
                    "type": {
                        "nonModel": "AccessStep"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "risk": {
                    "name": "risk",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": false,
                    "attributes": []
                },
                "collectiveHeating": {
                    "name": "collectiveHeating",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "privateHeating": {
                    "name": "privateHeating",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "elevator": {
                    "name": "elevator",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "visitGlobalInformation": {
                    "name": "visitGlobalInformation",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "visitAttentionPoints": {
                    "name": "visitAttentionPoints",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "prerequisites": {
                    "name": "prerequisites",
                    "isArray": true,
                    "type": {
                        "nonModel": "Prerequisite"
                    },
                    "isRequired": true,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "doorOpeningInstructions": {
                    "name": "doorOpeningInstructions",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "ownership": {
                    "name": "ownership",
                    "isArray": false,
                    "type": {
                        "enum": "OwnershipEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "firstCallGlobalInformation": {
                    "name": "firstCallGlobalInformation",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "firstCallAttentionPoints": {
                    "name": "firstCallAttentionPoints",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "Steps": {
            "name": "Steps",
            "fields": {
                "client": {
                    "name": "client",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "apartment": {
                    "name": "apartment",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "rooms": {
                    "name": "rooms",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "equipments": {
                    "name": "equipments",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "prerequisites": {
                    "name": "prerequisites",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "tags": {
                    "name": "tags",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "businessOffer": {
                    "name": "businessOffer",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "insurance": {
                    "name": "insurance",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "logistics": {
                    "name": "logistics",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "photos": {
                    "name": "photos",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "access": {
                    "name": "access",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "recap": {
                    "name": "recap",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                },
                "next": {
                    "name": "next",
                    "isArray": false,
                    "type": "Boolean",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "Prerequisite": {
            "name": "Prerequisite",
            "fields": {
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "status": {
                    "name": "status",
                    "isArray": false,
                    "type": {
                        "enum": "PrerequisiteStatusEnum"
                    },
                    "isRequired": false,
                    "attributes": []
                },
                "comment": {
                    "name": "comment",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "Photo": {
            "name": "Photo",
            "fields": {
                "key": {
                    "name": "key",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "event": {
                    "name": "event",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "sequencer": {
                    "name": "sequencer",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "path": {
                    "name": "path",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "bucket": {
                    "name": "bucket",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "AccessStep": {
            "name": "AccessStep",
            "fields": {
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                },
                "position": {
                    "name": "position",
                    "isArray": false,
                    "type": "Int",
                    "isRequired": true,
                    "attributes": []
                },
                "value": {
                    "name": "value",
                    "isArray": false,
                    "type": "String",
                    "isRequired": true,
                    "attributes": []
                }
            }
        },
        "Access": {
            "name": "Access",
            "fields": {
                "steps": {
                    "name": "steps",
                    "isArray": true,
                    "type": {
                        "nonModel": "AccessStep"
                    },
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "RentalAgency": {
            "name": "RentalAgency",
            "fields": {
                "name": {
                    "name": "name",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "contactName": {
                    "name": "contactName",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "email": {
                    "name": "email",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "phoneNumber": {
                    "name": "phoneNumber",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "description": {
                    "name": "description",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "address": {
                    "name": "address",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "Keyword": {
            "name": "Keyword",
            "fields": {
                "code": {
                    "name": "code",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                }
            }
        },
        "Room": {
            "name": "Room",
            "fields": {
                "type": {
                    "name": "type",
                    "isArray": false,
                    "type": "String",
                    "isRequired": false,
                    "attributes": []
                },
                "items": {
                    "name": "items",
                    "isArray": true,
                    "type": "String",
                    "isRequired": false,
                    "attributes": [],
                    "isArrayNullable": true
                }
            }
        }
    },
    "codegenVersion": "3.4.4",
    "version": "ecf0ffde39b1d338d9b2336b31e31721"
};