import { IDBPDatabase } from "idb";

interface PseudoFile {
  buffer: ArrayBuffer;
  folder: string;
  name: string;
  type: string;
}

const pseudoFileStore = {
  key: ["folder" as const, "name" as const],
  indexes: {
    byFolder: "folder" as PseudoFile["folder"],
  },
  value: {
    buffer: "ArrayBuffer",
    folder: "string",
    name: "string",
    type: "string",
  } as unknown as PseudoFile,
};

export const fileSchema = {
  "local-files": pseudoFileStore,
};

export const version = 1;

export type FileDB = IDBPDatabase<typeof fileSchema>;
