import { useSearchParams } from "react-router-dom";
import { useCurrentVisit } from "../data/useVisits";
import { Visit } from "../models";
import { Page } from "./Page";
import steps from "./steps";
import { getNextStep, getPreviousStep } from "./steps/steps";
import { StepValues } from "./steps/typings";

export const VisitForm: React.FC = () => {
  const visit = useCurrentVisit();
  return visit ? <V key={visit.id} visit={visit} /> : <div>NOT FOUND 31</div>;
};

export const V: React.FC<{ visit: Visit }> = ({ visit }) => {
  const [searchParams] = useSearchParams();

  return (
    <>
      {Object.entries(steps).map(([step, Component]) => (
        <Page key={step}>
          <Component
            {...{
              visit,
              current: searchParams.get("step") as StepValues,
              previous: getPreviousStep(step as StepValues),
              next: getNextStep(step as StepValues),
            }}
          />
        </Page>
      ))}
    </>
  );
};
