import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Collapse,
  TextField,
  Typography,
  styled,
} from "@mui/material";

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { useTranslation } from "react-i18next";
import { LazyPrerequisite, PrerequisiteStatusEnum } from "../models";

interface SubCardProps {
  icon: JSX.Element;
  color: string;
  status: keyof typeof PrerequisiteStatusEnum;
  handleChange: (status: keyof typeof PrerequisiteStatusEnum) => void;
  isSelected: boolean;
}

const StyledButton = styled(Button)(({ theme }) => ({
  justifyContent: "unset",
  textTransform: "unset",
  color: "inherit",
  borderColor: "inherit",
  "&:hover": {
    borderColor: "inherit",
    border: "unset",
    boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
  },
  borderRadius: theme.spacing(5),
  border: "unset",
  boxShadow: `0px 0px 4px 0px rgba(0, 0, 0, 0.25)`,
  padding: theme.spacing(1.5),
  "& .MuiButton-startIcon": {
    marginLeft: "unset",
  },
}));

const SubCard = ({
  icon,
  color,
  status,
  handleChange,
  isSelected,
}: SubCardProps) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "prerequisite.status",
  });

  return (
    <CardContent
      sx={{
        paddingX: 2,
        paddingY: 1,
        "&:last-child": {
          paddingBottom: 1,
        },
      }}
    >
      <StyledButton
        onClick={() => handleChange(status)}
        fullWidth
        variant={isSelected ? "contained" : "outlined"}
        sx={[
          (theme) => ({
            color: isSelected ? theme.palette.common.white : "",
            backgroundColor: isSelected ? color : "",
            "&:hover": {
              backgroundColor: isSelected ? color : "transparent",
            },
          }),
        ]}
        startIcon={
          <Box display="flex" color={isSelected ? "common.white" : color}>
            {icon}
          </Box>
        }
      >
        <Typography>{t(status)}</Typography>
      </StyledButton>
    </CardContent>
  );
};

export const PrerequisiteCard = ({
  prerequisite,
  handleChange,
}: {
  prerequisite: LazyPrerequisite;
  handleChange: (prerequisite: LazyPrerequisite) => void;
}) => {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation("translation", {
    keyPrefix: "prerequisite",
  });
  const iconStatus = {
    CONFORM: {
      title: t("status.conform"),
      icon: <CheckCircleOutlineIcon />,
      color: "success.light",
    },
    PENDING: {
      title: t("status.pending"),
      icon: <ErrorOutlineIcon />,
      color: "warning.light",
    },
    REFUSED: {
      title: t("status.refused"),
      icon: <HighlightOffIcon />,
      color: "error.light",
    },
  } as const;

  const handleStatusChange = (status: keyof typeof PrerequisiteStatusEnum) => {
    handleChange({ ...prerequisite, status });
  };

  return (
    <Card
      sx={{
        display: "flex",
        borderRadius: 5,
        flexDirection: "column",
        marginY: 2,
      }}
    >
      <CardActionArea disableTouchRipple onClick={() => setExpanded(!expanded)}>
        <CardContent>
          <Box sx={{ display: "flex" }}>
            <Box
              mr={1}
              display="flex"
              color={
                prerequisite.status
                  ? iconStatus[prerequisite.status].color
                  : "text.secondary"
              }
            >
              {prerequisite.status ? (
                iconStatus[prerequisite.status].icon
              ) : (
                <HelpOutlineIcon />
              )}
            </Box>
            <Box>
              <Typography color={prerequisite.status ? "" : "text.secondary"}>
                {t(`type.${prerequisite.type}`)}
              </Typography>
            </Box>
          </Box>
        </CardContent>
      </CardActionArea>
      <Collapse sx={{ mb: 1 }} in={expanded} timeout="auto" unmountOnExit>
        {(
          Object.keys(PrerequisiteStatusEnum) as Array<
            keyof typeof PrerequisiteStatusEnum
          >
        ).map((status, index) => (
          <SubCard
            key={`${index}_${status}`}
            handleChange={handleStatusChange}
            status={status}
            icon={iconStatus[status].icon}
            color={iconStatus[status].color}
            isSelected={prerequisite.status === status}
          />
        ))}
        {prerequisite.status && prerequisite.status !== "CONFORM" && (
          <TextField
            value={prerequisite.comment || ""}
            fullWidth
            multiline
            required
            rows={2}
            placeholder={t("comment.placeholder") || ""}
            onChange={(e) =>
              handleChange({ ...prerequisite, comment: e.target.value })
            }
            sx={{
              paddingX: 2,
              "& fieldset": {
                borderRadius: 2,
                border: "unset",
                boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)",
              },
            }}
          />
        )}
      </Collapse>
    </Card>
  );
};
