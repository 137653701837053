import {
  Avatar,
  Box,
  CircularProgress,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useAuthenticate } from "./authenticate";
import { useVisits } from "../data/useVisits";
import { Outlet, useSearchParams } from "react-router-dom";
import { HomePage, PageContainer } from "./Page";
import { VisitList } from "./VisitList";
import { stepOrder } from "./steps/steps";
import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import logo from "../assets/smartrenting-logo.png";
import { LoadingButton } from "@mui/lab";

export const Home: React.FC = () => {
  useAuthenticate();
  const visits = useVisits();
  const { user, signOut } = useAuthenticator();
  const [search] = useSearchParams();
  const step = search.get("step");
  const [textFilter, setTextFilter] = useState("");
  const [loading, setLoading] = useState(false);

  const pageIndex = stepOrder.indexOf(step as any) + 1;

  const handleSearch = (event: any) => {
    const text = event.target.value?.toLowerCase();
    setTextFilter(text);
  };

  const syncVisits = () => {
    if (loading) return;

    setLoading(true);

    const lambdaURL = process.env.REACT_APP_LAMBDA_GET_VISITS_URL as string;
    const lambdaKey = process.env.REACT_APP_LAMBDA_GET_VISITS_KEY as string;

    fetch(lambdaURL, {
      mode: "cors",
      method: "GET",
      headers: {
        "x-api-key": lambdaKey,
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json().then(console.log))
      .then(() => setLoading(false));
  };

  const filteredVisits = (visits?.items || []).filter(({ origin }) => {
    return (
      origin?.client?.firstname?.toLowerCase().includes(textFilter) ||
      origin?.client?.lastname?.toLowerCase().includes(textFilter) ||
      origin?.apartment?.address?.toLowerCase().includes(textFilter)
    );
  });

  return user ? (
    <Box
      display="flex"
      flexDirection="row"
      height="100dvh"
      position="fixed"
      sx={{
        transition: "left 0s ease-in-out",
        left: { xs: `-${pageIndex * 100}vw`, sm: "0" },
      }}
    >
      {visits ? (
        <>
          <HomePage>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginBottom: 4,
              }}
            >
              <img src={logo} alt="Logo Smartrenting" width="50%" />
              <Box
                sx={{
                  display: "flex",
                  marginTop: 4,
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <TextField
                  label="Recherche"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleSearch}
                  variant="outlined"
                  size="small"
                />
                <Avatar
                  variant="rounded"
                  onClick={syncVisits}
                  sx={{
                    borderRadius: 2,
                    backgroundColor: (theme) => theme.colorBorder,
                  }}
                >
                  {loading ? (
                    <LoadingButton loading={loading} />
                  ) : (
                    <RefreshIcon />
                  )}
                </Avatar>
                <Avatar
                  variant="rounded"
                  onClick={signOut}
                  sx={{
                    borderRadius: 2,
                    backgroundColor: (theme) => theme.colorSecondary,
                  }}
                >
                  <PowerSettingsNewIcon />
                </Avatar>
              </Box>
            </Box>
            <VisitList visits={filteredVisits} />
          </HomePage>
          <PageContainer>
            <Outlet />
          </PageContainer>
        </>
      ) : (
        <CircularProgress />
      )}
    </Box>
  ) : null;
};
