import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { StepFormContainer } from "../StepFormContainer";
import { StepFC } from "./typings";
import { Typography } from "@mui/material";
import EquipmentList, {
  equipmentsDefault,
} from "../EquipmentList/EquipmentList";
import { EquipmentName } from "../Equipment/Equipment";
import { DataStore } from "aws-amplify";
import { Visit } from "../../models";
import { useNavigate } from "react-router-dom";

export const defaultEquipments: Array<EquipmentName> = [
  "mop",
  "washer",
  "microwave",
  "hoover",
  "oven",
  "shower",
  "coffee-machine",
  "television",
  "fridge",
  "yard-sight",
  "street-sight",
  "elevator",
];

export const Equipments: StepFC = ({ previous, visit, next }) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "equipments",
  });

  const navigate = useNavigate();

  const equip: any[] =
    visit.updated?.apartment?.equipments ||
    visit.origin?.apartment?.equipments ||
    [];

  const [equipments, setEquipments] = useState({
    default: (equip || []).filter((eq: EquipmentName) =>
      defaultEquipments.includes(eq)
    ),
    other: (equip || []).filter(
      (eq: EquipmentName) => !defaultEquipments.includes(eq)
    ),
  });

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    return DataStore.save(
      Visit.copyOf(visit, (draft) => {
        if (!draft.updated) {
          draft.updated = {};
        }

        if (!draft.updated.steps) {
          draft.updated.steps = {};
        }

        if (!draft.updated.apartment) {
          draft.updated.apartment = {};
        }

        draft.updated.steps.equipments = true;
        draft.updated.apartment.equipments = [
          ...equipments.default,
          ...equipments.other,
        ];
      })
    ).then(() => {
      navigate(`?step=${next}`, {
        relative: "path",
      });
    });
  };

  const handleChangeDefault = ({
    target,
  }: React.ChangeEvent<HTMLInputElement>) => {
    setEquipments({ ...equipments, [target.name]: target.value });
  };

  return (
    <StepFormContainer
      previous={previous}
      onSubmit={handleSubmit}
      title={t("title")}
      disabled={
        equipments.default.length === 0 && equipments.other.length === 0
      }
      visit={visit}
    >
      <Typography color="text.secondary" mb={2} ml={1} sx={{ fontWeight: 500 }}>
        {t("defaultEquipments")}
      </Typography>
      <EquipmentList
        name={"default"}
        equipments={defaultEquipments}
        onChange={handleChangeDefault}
        value={equipments.default}
      />

      <Typography color="text.secondary" mb={2} ml={1} sx={{ fontWeight: 500 }}>
        {t("allEquipments")}
      </Typography>
      <EquipmentList
        name={"other"}
        onChange={handleChangeDefault}
        equipments={equipmentsDefault.filter(
          (equipment) => !defaultEquipments.includes(equipment)
        )}
        value={equipments.other}
      />
    </StepFormContainer>
  );
};
