import { Storage } from "aws-amplify";
import files from "./files";
import queue, { QueueState } from "./queue";
import * as actions from "./queue/actions";
import * as selectors from "./queue/selectors";

const dbName = "photos";

const secondes = 1_000;
const minute = 60 * secondes;

const retriesTimeouts = [
  0 * secondes,
  0 * secondes,
  5 * secondes,
  25 * secondes,
  30 * secondes,
  1 * minute,
  1 * minute,
  1 * minute,
  1 * minute,
  5 * minute,
  5 * minute,
  15 * minute,
  15 * minute,
  15 * minute,
];

class PhotoStore {
  /**
   * @private
   * @description indexedDB instance promise
   * - get all files currently in cache
   * - rehydrate the redux store with a starting queue
   */
  private db = files(dbName).then((db) =>
    db
      .transaction("local-files", "readonly")
      .objectStore("local-files")
      .getAllKeys()
      .then((keys) => {
        this.store.dispatch(
          actions.rehydrate(keys.map(([folder, name]) => ({ folder, name })))
        );
      })
      .then(() => db)
  );
  /**
   * @private
   * @description redux store with the state of all files
   */
  private store = queue();
  private cancelCurrentWorkerHandle?: () => void;
  /**
   * @private
   * @description worker instance
   * The worker is a function listening to redux state emitted
   * - when the worker is idle, it checks if a job is ready to become active
   * - it set the worker as active then start uploading
   */
  private worker = this.store.subscribe(() => {
    if (selectors.workerAvailable(this.store.getState())) {
      /**
       * @pattern the worker pessimistically locks further parallelisation
       */
      this.store.dispatch(actions.workerActive());
      const work = selectors.currentWork(this.store.getState());
      if (work.status === "READY") {
        const isCurrent = selectors.isCurrentJob(work.name, work.folder);
        /**
         * the worker starts working immediately and uploads the current active job
         */

        this.db
          .then((db) =>
            db
              .transaction("local-files", "readonly")
              .objectStore("local-files")
              .get([work.folder, work.name] as ["folder", "name"])
              .then((result) => {
                if (result) return result;
                /**
                 * @bug for a reason, a file is referenced in the job queue but not in the indexedDB
                 */ else {
                  this.store.dispatch(
                    actions.fileMissing(work.name, work.folder)
                  );
                  throw new Error(`MISSING FILE ${work}`);
                }
              })
              .then(({ folder, name, type, buffer }) => {
                /**
                 * @todo
                 * when upload fails, retries again and again and again and again
                 * keep the buffer alive
                 */
                return new Promise((resolved, rejected) => {
                  if (!isCurrent(this.store.getState())) {
                    return resolved(void 0);
                  }
                  const put = () => {
                    if (!isCurrent(this.store.getState())) {
                      return resolved(void 0);
                    }
                    // on passe en mode upload avec un try ++
                    this.store.dispatch(actions.uploadStart(name, folder));
                    const handle = Storage.put(`${folder}/${name}`, buffer, {
                      level: "public",
                      contentType: type,
                      progressCallback: (progress) => {
                        this.store.dispatch(
                          actions.uploadProgress(
                            work.name,
                            work.folder,
                            progress.loaded / progress.total
                          )
                        );
                      },
                    });
                    handle.then((res) => {
                      this.store.dispatch(
                        actions.uploadDone(work.name, work.folder)
                      );
                      db.transaction("local-files", "readwrite")
                        .objectStore("local-files")
                        .delete([work.folder, work.name] as ["folder", "name"])
                        .then(() =>
                          this.store.dispatch(
                            actions.fileDeleted(work.name, work.folder)
                          )
                        );
                      resolved(res);
                    }, defered);
                    this.cancelCurrentWorkerHandle = () => {
                      Storage.cancel(handle);
                    };
                  };
                  const defered = (err?: any) => {
                    if (
                      Storage.isCancelError(err) ||
                      !isCurrent(this.store.getState())
                    ) {
                      return resolved(void 0);
                    }
                    console.error("ERREUR IC", err);
                    // si pas la bonne erreur, sortir avec le rejected
                    this.store.dispatch(actions.workerActive());
                    const a = setTimeout(
                      put,
                      retriesTimeouts[
                        selectors.retries(this.store.getState())
                      ] ?? 60 * minute
                    );
                    this.cancelCurrentWorkerHandle = () => {
                      clearTimeout(a);
                    };
                  };
                  put();
                });
              })
              .then(() => {
                this.cancelCurrentWorkerHandle = undefined;
              })
          )
          .catch(console.error);
      }
    }
  });
  /**
   * @public
   * @description Promise resolved when db is ready to handle transaction, but keeps the db private
   */
  public ready = () => this.db.then(() => void 0);

  public storeFiles = (
    files: Array<{ blob: Blob; name: string; folder: string }>
  ) => {
    this.store.dispatch(actions.filesCaptured(files));
    return Promise.all([
      this.db,
      ...files.map((item) =>
        item.blob.arrayBuffer().then((buffer) => ({ ...item, buffer }))
      ),
    ]).then(([db, ...blobFiles]) => {
      const tx = db
        .transaction("local-files", "readwrite")
        .objectStore("local-files");
      return Promise.all(
        blobFiles.map(({ buffer, blob, name, folder }) =>
          tx.put({ buffer, type: blob.type, name, folder }).then(
            () =>
              this.store.dispatch(
                actions.filePersistedSuccess(name, folder, blob)
              ),
            (err) =>
              this.store.dispatch(
                actions.filePersistedError(name, folder, err, blob)
              )
          )
        )
      );
    });
  };

  public subscribe = (cb: (state: QueueState) => any) =>
    this.store.subscribe(() => cb(this.store.getState()));

  public removeFile = (name: string, folder: string) => {
    const work = selectors.currentWork(this.store.getState());
    if (
      work.status !== "IDLE" &&
      work.folder === folder &&
      work.name === name &&
      this.cancelCurrentWorkerHandle
    ) {
      this.cancelCurrentWorkerHandle();
    }
    this.store.dispatch(actions.fileCanceled(name, folder));
    this.db.then((db) =>
      db
        .transaction("local-files", "readwrite")
        .objectStore("local-files")
        .delete([folder, name] as ["folder", "name"])
        .then(() => this.store.dispatch(actions.fileDeleted(name, folder)))
    );
  };
}

/**
 * @description Singleton managing photo file for the whole application
 * - wraps a redux state management with a subscribe interface
 * - manages side effects for caching files in indexDB and persisting file to aws bucket
 * @todo export const photo = isLocalhost ? new LocalPhotoStore() : new PhotoStore();
 */

// eslint-disable-next-line @typescript-eslint/no-unused-vars
class LocalPhotoStore {}

export const photo = new PhotoStore();
