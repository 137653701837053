import React, { ChangeEvent } from "react";
import { useTranslation } from "react-i18next";

import { RoomItemInputWrapper } from "./RoomItemInput.style";
import { TextField, Theme, Typography } from "@mui/material";
import KingBedOutlinedIcon from "@mui/icons-material/KingBedOutlined";
import SingleBedOutlinedIcon from "@mui/icons-material/SingleBedOutlined";
import ChairOutlinedIcon from "@mui/icons-material/ChairOutlined";
import ShowerOutlinedIcon from "@mui/icons-material/ShowerOutlined";
import BathtubOutlinedIcon from "@mui/icons-material/BathtubOutlined";
import AirlineSeatReclineNormalIcon from "@mui/icons-material/AirlineSeatReclineNormal";

export interface RoomItemInputProps {
  itemType: string;
  onChange: CallableFunction;
  value?: string;
}

const MAX_ITEM = 19;
export default function RoomItemInput({
  itemType,
  onChange,
  value,
}: RoomItemInputProps) {
  const { t } = useTranslation();

  const handleChange = (event: ChangeEvent) => {
    const target = event.target as HTMLInputElement;
    const value = Math.min(parseInt(target.value, 10), MAX_ITEM);

    onChange(value || 0, itemType);
  };

  const sx = (theme: Theme) => ({
    color: theme.colorSecondary,
  });

  const Icon: Record<string, JSX.Element> = {
    "bed-140": <KingBedOutlinedIcon sx={[sx]} />,
    "bed-160": <KingBedOutlinedIcon sx={[sx]} />,
    "bed-180": <KingBedOutlinedIcon sx={[sx]} />,
    "bed-couch": <ChairOutlinedIcon sx={[sx]} />,
    "bed-bunk": <KingBedOutlinedIcon sx={[sx]} />,
    "bed-solo": <SingleBedOutlinedIcon sx={[sx]} />,
    shower: <ShowerOutlinedIcon sx={[sx]} />,
    bath: <BathtubOutlinedIcon sx={[sx]} />,
    toilets: <AirlineSeatReclineNormalIcon sx={[sx]} />,
  };

  return (
    <RoomItemInputWrapper>
      <div className="RoomItemInput__icon">
        {Icon[itemType]}
        <Typography
          sx={[
            (theme) => ({
              color: theme.colorSecondary,
            }),
          ]}
          ml="0.5rem"
          width="15ch"
          variant="body2"
        >
          {t(`equipment.${itemType}`)}
        </Typography>
      </div>
      <TextField
        InputProps={{
          style: { width: "4rem", height: "2rem" },
        }}
        inputProps={{
          min: 0,
          max: MAX_ITEM,
          style: { textAlign: "center" },
          pattern: "\\d*",
        }}
        sx={{
          textAlign: "center",
        }}
        name={itemType}
        size="small"
        type="number"
        placeholder="0"
        onChange={handleChange}
        defaultValue={value}
      />
    </RoomItemInputWrapper>
  );
}
