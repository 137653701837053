import React, { useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { RoomType, ROOM_ITEM_BY_ROOM } from "../../RoomsComponent";
import RoomItemInput from "../RoomItemInput/RoomItemInput";
import { RoomWrapper } from "./Room.style";
import { Divider, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export interface RoomProps {
  title: string;
  roomType: "livingroom" | "bedroom" | "bathroom";
  isFirst: boolean;
  onChange: CallableFunction;
  room: RoomType;
  collapsible?: boolean;
  /**
   * collapsed will be undefined at initial state if parent doesn't have the props collapsed.
   * We need it at undefined at first so it will trigger the useEffect to collapse the room after a save otherwise it will not work.
   * Props will change from undefined to true instead of true (room are collapsed by default) to true which will not trigger the useEffect.
   */
  collapsed: boolean | undefined;
}

export default function Room({
  title,
  roomType,
  isFirst,
  onChange,
  room,
  collapsible,
  collapsed,
}: RoomProps) {
  const { t } = useTranslation();
  const itemTypes = ROOM_ITEM_BY_ROOM[roomType];

  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleChange = (value: string, itemType: string) => {
    const newItems = { ...room.items, [itemType]: parseInt(value, 10) };

    onChange({
      ...room,
      items: newItems,
    });
  };

  useEffect(() => {
    if (collapsed === undefined) setIsCollapsed(true);
    if (collapsed !== undefined) setIsCollapsed(collapsed);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const resume = useMemo(() => {
    return collapsible && isCollapsed
      ? Object.entries(room.items)
          .reduce((acc: any, [item, count]) => {
            if (count > 0) {
              return [
                ...acc,
                `${count} ${t(`equipment.${item}`, {
                  count: count as number,
                })}`,
              ];
            }

            return acc;
          }, [])
          .join(", ")
      : "";
  }, [t, room, collapsible, isCollapsed]);

  return (
    <RoomWrapper>
      {!isFirst && <Divider sx={{ marginY: 1 }} />}
      <div
        className="Room__header"
        role="button"
        onClick={() => setIsCollapsed(!isCollapsed)}
        aria-hidden="true"
      >
        <Typography
          fontWeight={(theme) => theme.boldRegular}
          className="Room__header__title"
          variant="body2"
        >
          {title}
        </Typography>
        {isCollapsed && (
          <Typography className="Room__header__resume">{resume}</Typography>
        )}

        {collapsible && (
          <ChevronRightIcon
            className={`Room__header__collapse ${
              !isCollapsed ? "Room__header__collapse--extend" : ""
            }`}
          />
        )}
      </div>
      <div
        className={`Room__items ${
          !isCollapsed || !collapsible ? "Room__items--extended" : ""
        }`}
      >
        {itemTypes.map((itemType) => (
          <RoomItemInput
            key={itemType}
            itemType={itemType}
            value={(room.items[itemType] as unknown as string) || ""}
            onChange={handleChange}
          />
        ))}
      </div>
    </RoomWrapper>
  );
}
