import { createHashRouter } from "react-router-dom";
import { Home } from "./components/Home";
import { VisitForm } from "./components/Visit";

export const router = createHashRouter([
  {
    path: "/",
    element: <Home />,
    children: [
      {
        path: ":visitId",
        element: <VisitForm />,
      },
    ],
  },
]);
