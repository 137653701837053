import React, { useState } from "react";
import { StepFC } from "./typings";
import { StepFormContainer } from "../StepFormContainer";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";

import { DataStore } from "aws-amplify";
import { LeavingData, Visit, OwnershipEnum } from "../../models";
import { useNavigate } from "react-router-dom";

export const BusinessOffer: StepFC = ({ visit, previous, next }) => {
  const navigate = useNavigate();
  const [managedByAgency, setManagedByAgency] = useState(
    !!visit?.updated?.apartment?.rentalAgency ||
      !!visit?.origin?.apartment?.rentalAgency
  );

  const { t } = useTranslation("translation", {
    keyPrefix: "businessOffer",
  });

  const { origin, updated } = visit;

  const extractValue = (key: keyof LeavingData) => {
    return updated?.leaving?.[key] ?? origin?.leaving?.[key];
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.target as HTMLFormElement);
    const inputValue: Record<string, any> = {
      agency: {},
    };

    Array.from(data.entries()).forEach(([name, value]) => {
      const fieldName = name.split(".");

      if (fieldName.length > 1) {
        inputValue[fieldName[0]] = {
          ...(inputValue[fieldName[0]] || {}),
          [fieldName[1]]: value || null,
        };

        return;
      }

      inputValue[name] = Number(value) ?? value ?? null;
    });

    return DataStore.save(
      Visit.copyOf(visit, (draft) => {
        const { agency, ...leaving } = inputValue;
        if (!draft.updated) {
          draft.updated = {};
        }

        if (!draft.updated.steps) {
          draft.updated.steps = {};
        }

        if (!draft.updated.leaving) {
          draft.updated.leaving = {};
        }

        if (!draft.updated.apartment) {
          draft.updated.apartment = {};
        }

        draft.updated.steps.businessOffer = true;
        draft.updated.leaving = leaving;
        draft.updated.apartment.rentalAgency = managedByAgency ? agency : null;
      })
    ).then(() => {
      navigate(`?step=${next}`, {
        relative: "path",
      });
    });
  };

  const defaultValue = {
    rent: extractValue("rent"),
    monthlyCharges: extractValue("monthlyCharges"),
    allowance: extractValue("allowance"),
    agency: updated?.apartment?.rentalAgency ?? origin?.apartment?.rentalAgency,
  };

  const ownership =
    updated?.apartment?.ownership ?? visit.origin?.apartment?.ownership;

  return (
    <StepFormContainer
      previous={previous}
      onSubmit={handleSubmit}
      title={t("title")}
      visit={visit}
    >
      <TextField
        name="rent"
        label={t(`rent.${ownership}`)}
        InputProps={{
          endAdornment: <InputAdornment position="end">€</InputAdornment>,
        }}
        inputProps={{
          pattern: "\\d*",
        }}
        type="number"
        defaultValue={defaultValue.rent}
        required
      />
      {ownership !== OwnershipEnum.OWNER && (
        <TextField
          name="monthlyCharges"
          label={t("monthlyCharges")}
          InputProps={{
            endAdornment: <InputAdornment position="end">€</InputAdornment>,
          }}
          inputProps={{
            pattern: "\\d*",
          }}
          type="number"
          defaultValue={defaultValue.monthlyCharges}
          required
        />
      )}
      {ownership !== OwnershipEnum.OWNER && (
        <TextField
          name="allowance"
          label={t("allowance")}
          InputProps={{
            endAdornment: <InputAdornment position="end">€</InputAdornment>,
          }}
          inputProps={{
            pattern: "\\d*",
          }}
          type="number"
          defaultValue={defaultValue.allowance}
        />
      )}
      <Divider sx={{ marginY: 1 }} />
      <FormLabel focused={false} id="rentalAgency">
        {t("rentalAgency")}
      </FormLabel>
      <FormControlLabel
        control={
          <Checkbox
            checked={managedByAgency}
            onChange={(event) => {
              setManagedByAgency(event.target.checked);
            }}
          />
        }
        label={t("managedByAgency")}
      />
      {managedByAgency && (
        <>
          <TextField
            name="agency.name"
            label={t("agency.name")}
            defaultValue={defaultValue?.agency?.name}
            required={managedByAgency}
          />
          <TextField
            name="agency.contactName"
            label={t("agency.contactName")}
            defaultValue={defaultValue?.agency?.contactName}
          />
          <TextField
            name="agency.address"
            label={t("agency.address")}
            defaultValue={defaultValue?.agency?.address}
          />
          <TextField
            name="agency.email"
            label={t("agency.email")}
            defaultValue={defaultValue?.agency?.email}
            type="email"
          />
          <TextField
            name="agency.phoneNumber"
            label={t("agency.phoneNumber")}
            defaultValue={defaultValue?.agency?.phoneNumber}
            inputProps={{
              pattern: "\\d*",
            }}
            type="tel"
          />
          <TextField
            name="agency.description"
            label={t("agency.description")}
            defaultValue={defaultValue?.agency?.description}
          />
        </>
      )}
    </StepFormContainer>
  );
};
