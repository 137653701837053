import { StepFC, StepValues } from "./typings";
import { ClientInformations } from "./ClientInformations";
import { ApartmentInformations } from "./ApartmentInformations";
import { Photos } from "./Photos";
import { Rooms } from "./Rooms";
import { Equipments } from "./Equipments";
import { Tags } from "./Tags";
import { BusinessOffer } from "./BusinessOffer";
import { Insurance } from "./Insurance";
import { Logistics } from "./Logistics";
import { Access } from "./Access";
import { Recap } from "./Recap";
import { Next } from "./Next";
import { Prerequisite } from "./Prerequisite";

const steps: Partial<Record<StepValues, StepFC>> = {
  client: ClientInformations,
  apartment: ApartmentInformations,
  rooms: Rooms,
  equipments: Equipments,
  prerequisites: Prerequisite,
  tags: Tags,
  businessOffer: BusinessOffer,
  insurance: Insurance,
  logistics: Logistics,
  photos: Photos,
  access: Access,
  next: Next,
  recap: Recap,
};

export default steps;
