import { styled } from "@mui/material";

export const KeywordBadgeWrapper = styled("button")`
  &.KeywordBadge {
    &__keyword {
      padding: 0.3rem 1rem;
      margin: 0.3rem;
      font-size: 1rem;
      color: ${({ theme }) => theme.gray400};
      user-select: none;
      background-color: ${({ theme }) => theme.gray100};
      border: 1px solid ${({ theme }) => theme.gray400};
      border-radius: 1.5rem;
      transition: 0.2s linear background-color;

      &--available {
        padding: 0.3rem 1rem;
        margin: 0.3rem;
        font-size: 1rem;
        color: #a7a7a7;
        cursor: pointer;
        user-select: none;
        background-color: ${({ theme }) => theme.colorWhite};
        border: 1px solid ${({ theme }) => theme.gray400};
        border: 1px solid #a7a7a7;
        border-radius: 1.5rem;
        transition: 0.2s linear background-color;
      }

      &--selected {
        color: ${({ theme }) => theme.colorPrimary};
        border-color: ${({ theme }) => theme.colorPrimary};
        background-color: #fbd5dd;
      }
    }
  }
`;
