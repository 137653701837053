import {
  AppBar,
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  Popover,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { LazySteps, LazyVisit } from "../models";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HelpTwoToneIcon from "@mui/icons-material/HelpTwoTone";
import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone";

interface StepFormContainerProps {
  previous: keyof LazySteps | null;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => Promise<void>;
  children: React.ReactNode;
  submitButton?: React.ReactNode;
  disabled?: boolean;
  title: string;
  isLastStep?: boolean;
  visit: LazyVisit;
}

const HelpContent = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        padding: 2,
      }}
    >
      <Typography
        sx={{
          background:
            "linear-gradient(89.73deg, #2D3282 -6.42%, #EF486B 102.07%)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          textAlign: "left",
          width: "fit-content",
        }}
        variant="h5"
      >
        {t("questions.title")}
      </Typography>
      <Divider sx={{ my: 1 }} />
      <Typography variant="body1" fontWeight="bold">
        {t("questions.assurance.title")}
      </Typography>
      <Typography variant="body2">{t("questions.assurance.text")}</Typography>
      <Divider variant="middle" sx={{ my: 1 }} />
      <Typography variant="body1" fontWeight="bold">
        {t("questions.tourism.title")}
      </Typography>
      <Typography variant="body2">{t("questions.tourism.text")}</Typography>
      <Divider variant="middle" sx={{ my: 1 }} />
      <Typography variant="body1" fontWeight="bold">
        {t("questions.authorization.title")}
      </Typography>
      <Typography variant="body2">
        {t("questions.authorization.text")}
      </Typography>
      <Divider variant="middle" sx={{ my: 1 }} />
      <Typography variant="body1" fontWeight="bold">
        {t("questions.usage.title")}
      </Typography>
      <Typography variant="body2">{t("questions.usage.text")}</Typography>
    </Box>
  );
};

const InfoContent = ({ visit }: { visit: LazyVisit }) => {
  const { t } = useTranslation();

  const { origin } = visit;

  return (
    <Box
      sx={{
        padding: 2,
      }}
    >
      <Typography
        sx={{
          background:
            "linear-gradient(89.73deg, #2D3282 -6.42%, #EF486B 102.07%)",
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
          textAlign: "left",
          width: "fit-content",
        }}
        variant="h5"
      >
        {t("prospectInfos.title")}
      </Typography>
      <Divider sx={{ my: 1 }} />
      <Typography variant="body1" fontWeight="bold">
        {t("prospectInfos.firstCallInfo")}
      </Typography>
      <Typography variant="body2">
        {origin?.apartment?.firstCallGlobalInformation ||
          t("prospectInfos.noData")}
      </Typography>
      <Divider variant="middle" sx={{ my: 1 }} />
      <Typography variant="body1" fontWeight="bold">
        {t("prospectInfos.attentionPoints")}
      </Typography>
      <Typography variant="body2">
        {origin?.apartment?.firstCallAttentionPoints ||
          t("prospectInfos.noData")}
      </Typography>
    </Box>
  );
};

export const StepFormContainer = ({
  onSubmit,
  submitButton,
  previous,
  children,
  title,
  disabled = false,
  isLastStep = false,
  visit,
}: StepFormContainerProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [anchorElHelp, setAnchorElHelp] =
    React.useState<HTMLButtonElement | null>(null);
  const [anchorElInfo, setAnchorElInfo] =
    React.useState<HTMLButtonElement | null>(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handlePrevious = () => {
    navigate(`?step=${previous}`, {
      relative: "path",
    });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    if (!isSubmitting) {
      setIsSubmitting(true);

      return onSubmit(event).then(() => {
        setTimeout(() => {
          setIsSubmitting(false);
        }, 1000); // Without this debouncing trick, the operation done on submit are way too fast, and returning before navigation changes, resulting in multiple submission of the same graphql query (and the merge of data)
      });
    }
  };

  const handleClickHelp = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElHelp(event.currentTarget);
  };

  const handleClickInfo = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElInfo(event.currentTarget);
  };

  const handleCloseHelp = () => {
    setAnchorElHelp(null);
  };

  const handleCloseInfo = () => {
    setAnchorElInfo(null);
  };

  const openHelp = Boolean(anchorElHelp);
  const idHelp = openHelp ? "simple-popover" : undefined;
  const openInfo = Boolean(anchorElInfo);
  const idInfo = openInfo ? "simple-popover" : undefined;

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      component="form"
      onSubmit={handleSubmit}
    >
      <AppBar
        position="sticky"
        sx={{
          marginBottom: 2,
          borderRadius: 3,
          background: "linear-gradient(90deg, #2D3282 0%, #EF486B 127.37%)",
        }}
      >
        <Toolbar variant="dense" sx={{ justifyContent: "space-between" }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handlePrevious}
          >
            <ChevronLeftIcon />
          </IconButton>
          <Typography
            variant="body1"
            fontWeight={500}
            color="inherit"
            component="div"
          >
            {title}
          </Typography>
          <Box sx={{ display: "flex" }}>
            <IconButton
              color="inherit"
              aria-label="menu"
              onClick={handleClickInfo}
              sx={{ padding: "4px" }}
            >
              <InfoTwoToneIcon />
            </IconButton>
            <IconButton
              color="inherit"
              aria-label="menu"
              onClick={handleClickHelp}
              sx={{ padding: "4px" }}
            >
              <HelpTwoToneIcon />
            </IconButton>
          </Box>
          <Popover
            id={idHelp}
            open={openHelp}
            anchorEl={anchorElHelp}
            onClose={handleCloseHelp}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <HelpContent />
          </Popover>
          <Popover
            id={idInfo}
            open={openInfo}
            anchorEl={anchorElInfo}
            onClose={handleCloseInfo}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <InfoContent visit={visit} />
          </Popover>
        </Toolbar>
      </AppBar>
      <FormControl
        fullWidth
        sx={{
          "& .MuiFormControl-root": { marginY: 1 },
          marginBottom: "auto",
          overflowY: "auto",
          "::-webkit-scrollbar": {
            display: "none",
          },
          paddingBottom: "64px",
        }}
      >
        {children}
      </FormControl>
      {!submitButton && (
        <Button
          sx={[
            (theme) => ({
              backgroundColor: theme.colorSecondary,
              borderRadius: theme.rem(25),
              marginBottom: window.matchMedia("(display-mode: standalone)")
                .matches
                ? 3
                : 0,
              marginTop: 2,
              fontWeight: 600,
              fontSize: 15,
            }),
          ]}
          variant="contained"
          type="submit"
          disabled={disabled || isSubmitting}
        >
          {isLastStep ? t("common.complete") : t("common.next")}
        </Button>
      )}
      {!!submitButton && submitButton}
    </Box>
  );
};
