import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { StepFC } from "./typings";

import WhatsNextSVG from "../../assets/whats_next.svg";
import {
  Box,
  List,
  ListItem,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { DataStore } from "aws-amplify";
import { Visit } from "../../models";
import { StepFormContainer } from "../StepFormContainer";
import TopicIcon from "@mui/icons-material/Topic";
import EditIcon from "@mui/icons-material/Edit";
import ScreenshotIcon from "@mui/icons-material/Screenshot";
import KeyIcon from "@mui/icons-material/Key";

export const Next: StepFC = ({ next, visit, previous }) => {
  const navigate = useNavigate();

  const { t } = useTranslation("translation", {
    keyPrefix: "whatsNext",
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    return DataStore.save(
      Visit.copyOf(visit, (draft) => {
        if (!draft.updated) {
          draft.updated = {};
        }

        if (!draft.updated.steps) {
          draft.updated.steps = {};
        }

        draft.updated.steps.next = true;
      })
    ).then(() =>
      navigate(`?step=${next}`, {
        relative: "path",
      })
    );
  };

  return (
    <StepFormContainer
      title={t("title")}
      previous={previous}
      onSubmit={handleSubmit}
      visit={visit}
    >
      <Box
        component="div"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            marginTop: 5,
            marginBottom: 8,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img src={WhatsNextSVG} alt="whats-next-svg" />
        </Box>
        <Stepper
          orientation="vertical"
          sx={{ ml: 6, mr: 2, color: (theme) => theme.colorTertiary }}
        >
          <Step key={"document"} active>
            <StepLabel icon={<TopicIcon />}>
              <Typography variant="h6">{t("document")}</Typography>
            </StepLabel>
            <StepContent>
              <List sx={{ fontSize: (theme) => theme.rem(12) }}>
                <ListItem>&bull;{t("idCard")}</ListItem>
                <ListItem>&bull;{t("rib")}</ListItem>
                <ListItem>&bull;{t("proofDocument")}</ListItem>
              </List>
            </StepContent>
          </Step>
          <Step key={"sendMandate"} active>
            <StepLabel icon={<EditIcon />}>
              <Typography variant="h6">{t("sendMandate")}</Typography>
            </StepLabel>
          </Step>
          <Step key={"inventory"} active>
            <StepLabel icon={<ScreenshotIcon />}>
              <Typography variant="h6">{t("inventory")}</Typography>
            </StepLabel>
          </Step>
          <Step key={"keys"} active>
            <StepLabel icon={<KeyIcon />}>
              <Typography variant="h6">{t("keys")}</Typography>
            </StepLabel>
          </Step>
        </Stepper>
      </Box>
    </StepFormContainer>
  );
};
