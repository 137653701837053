import React, { useState } from "react";
import { StepFormContainer } from "../StepFormContainer";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { StepFC } from "./typings";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
  TextField,
} from "@mui/material";

import { DataStore } from "aws-amplify";
import { ApartmentData, Visit } from "../../models";

export const Logistics: StepFC = ({ visit, next, previous }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("translation", {
    keyPrefix: "logistics",
  });
  const { origin, updated } = visit;

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = new FormData(event.target as HTMLFormElement);
    const inputValue = Object.fromEntries(data.entries());

    return DataStore.save(
      Visit.copyOf(visit, (draft) => {
        Object.entries(inputValue).forEach(([key, value]) => {
          if (!draft.updated) {
            draft.updated = {};
          }

          if (!draft.updated.apartment) {
            draft.updated.apartment = {};
          }

          draft.updated.apartment[key as unknown as never] = value as never;
        });

        if (!draft.updated) {
          draft.updated = {};
        }

        if (!draft.updated.steps) {
          draft.updated.steps = {};
        }

        if (!draft.updated.apartment) {
          draft.updated.apartment = {};
        }

        draft.updated.steps.logistics = true;
        draft.updated.apartment.collectiveHeating = heating.collectiveHeating;
        draft.updated.apartment.privateHeating = heating.privateHeating;
      })
    ).then(() =>
      navigate(`?step=${next}`, {
        relative: "path",
      })
    );
  };

  const extractValue = <T extends keyof ApartmentData>(
    key: T
  ): ApartmentData[T] => {
    return updated?.apartment?.[key] ?? origin?.apartment?.[key];
  };

  const defaultValue = {
    wifiInstructions: extractValue("wifiInstructions"),
    wifi: extractValue("wifi"),
    wifiPassword: extractValue("wifiPassword"),
    cleaningDirective: extractValue("cleaningDirective"),
    wasteInstructions: extractValue("wasteInstructions"),
    waterSupplyLocalization: extractValue("waterSupplyLocalization"),
    electricitySupplyLocalization: extractValue(
      "electricitySupplyLocalization"
    ),
  };

  const [heating, setHeating] = useState({
    collectiveHeating: extractValue("collectiveHeating") ?? false,
    privateHeating: extractValue("privateHeating") ?? false,
  });

  return (
    <StepFormContainer
      previous={previous}
      onSubmit={handleSubmit}
      title={t("title")}
      visit={visit}
    >
      <TextField
        name="wifiInstructions"
        label={t("wifiInstructions")}
        defaultValue={defaultValue.wifiInstructions}
      />
      <TextField
        name="wifi"
        label={t("wifi")}
        defaultValue={defaultValue.wifi}
      />
      <TextField
        name="wifiPassword"
        label={t("wifiPassword")}
        defaultValue={defaultValue.wifiPassword}
      />
      <TextField
        name="cleaningDirective"
        label={t("cleaningDirective")}
        defaultValue={defaultValue.cleaningDirective}
      />
      <TextField
        name="wasteInstructions"
        label={t("wasteInstructions")}
        defaultValue={defaultValue.wasteInstructions}
      />

      <TextField
        name="waterSupplyLocalization"
        label={t("waterSupplyLocalization")}
        defaultValue={defaultValue.waterSupplyLocalization}
      />
      <TextField
        name="electricitySupplyLocalization"
        label={t("electricitySupplyLocalization")}
        defaultValue={defaultValue.electricitySupplyLocalization}
      />
      <FormLabel id="heating" focused={false}>
        {t("heating")}
      </FormLabel>
      <FormGroup row>
        <FormControlLabel
          name="collectiveHeating"
          label={t("collectiveHeating")}
          control={
            <Checkbox
              checked={heating.collectiveHeating}
              onChange={(event) =>
                setHeating({
                  ...heating,
                  collectiveHeating: event.target.checked,
                })
              }
            />
          }
        />
        <FormControlLabel
          name="privateHeating"
          label={t("privateHeating")}
          control={
            <Checkbox
              checked={heating.privateHeating}
              onChange={(event) =>
                setHeating({
                  ...heating,
                  privateHeating: event.target.checked,
                })
              }
            />
          }
        />
      </FormGroup>
    </StepFormContainer>
  );
};
