import * as actionCreators from "./actions";

type AC = typeof actionCreators;
export type Actions = {
  [K in keyof AC]: ReturnType<AC[K]> extends { type: string }
    ? string extends ReturnType<AC[K]>["type"]
      ? never
      : ReturnType<AC[K]>
    : never;
}[keyof AC];

/**
 * @description user action entrypoint for all other actions, add entry in queue
 */
export const filesCaptured = (files: Array<{ name: string; folder: string }>) =>
  ({ type: "FILES_CREATED", files } as const);

/**
 * @description
 * asynchronous confirmation of file persisted
 * updates the entry status to persisted and add it to the queue
 */
export const filePersistedSuccess = (
  name: string,
  folder: string,
  blob: Blob
) => ({ type: "FILE_STORED", name, folder, blob } as const);

export const filePersistedError = (
  name: string,
  folder: string,
  error: any,
  blob: Blob
) => ({ type: "FILE_STORED_ERROR", name, folder, error, blob } as const);

/**
 * @description
 * at start, only once, recreates the queue from previously indexed entries, at the persisted state
 */
export const rehydrate = (queue: Array<{ name: string; folder: string }>) =>
  ({ type: "REHYDRATION", queue } as const);

export const workerActive = () => ({ type: "WORKER_ACTIVE" } as const);

/**
 * @description
 * updates entry status in queue, recreate worker data from this entry
 */
export const fileMissing = (name: string, folder: string) =>
  ({ type: "FILE_NOTFOUND", name, folder } as const);

/**
 * @description
 * updates entry status in queue, recreate worker data from this entry
 */
export const fileCanceled = (name: string, folder: string) =>
  ({ type: "FILE_CANCELED", name, folder } as const);
/**
 * @description
 * updates entry status in queue, recreate worker data from this entry
 */
export const uploadStart = (name: string, folder: string) =>
  ({ type: "UPLOAD_START", name, folder } as const);

/**
 * @description
 * updates entry status in queue, update worker data
 */
export const uploadProgress = (
  name: string,
  folder: string,
  progress: number
) =>
  ({
    type: "UPLOAD_PROGRESS",
    name,
    folder,
    progress,
  } as const);

/**
 * @description
 * update entry status in queue, destroy worker data
 */
export const uploadDone = (name: string, folder: string) =>
  ({
    type: "UPLOAD_DONE",
    name,
    folder,
  } as const);

/**
 * @description
 * when file is successfuly deleted from indexed db
 * destroy entry in queue, move it to "done" archive
 */
export const fileDeleted = (name: string, folder: string) =>
  ({
    type: "FILE_DELETED",
    name,
    folder,
  } as const);
