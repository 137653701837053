import { openDB } from "idb";
import type { DBSchema, StoreNames, IndexNames } from "idb";

interface Upgrade {
  from: number;
  version: number;
  migration: (db: IDBDatabase) => Promise<void>;
  init: (db: IDBDatabase) => Promise<void>;
}

export const createIndexedDBFromSchema =
  <T extends DBSchema>(schema: T, version: number, upgrades?: Upgrade[]) =>
  (name: string) =>
    openDB<T>(name, version, {
      upgrade(db, previousVersion, currentVersion, transaction) {
        /**
         * @todo graceful management of version upgrades
         *
         * si pas de previous : premiere install (browser vierge)
         * alors chercher l'upgrade au n° de version max et faire son init
         * si previous, chercher l'item au from === previous, puis chercher si il existe un item dont le from est le previous, etc.
         * jusqu'à ce que la version "max" soit trouvée
         */

        // version changed, remove all tables
        Array.from({ length: db.objectStoreNames.length }, (_, i) => i)
          .map((index) => db.objectStoreNames.item(index))
          .filter(notNull)
          .forEach((name) => db.deleteObjectStore(name));
        Object.entries(schema).forEach(([store, setup]) => {
          const loc = db.createObjectStore(store as StoreNames<T>, {
            keyPath: setup.key as any,
          });
          if (setup.indexes) {
            Object.entries(setup.indexes).forEach(([index, indexSetup]) =>
              loc.createIndex(
                index as IndexNames<T, StoreNames<T>>,
                indexSetup as any
              )
            );
          }
        });
      },
    });

function notNull<T>(arg: T | null): arg is T {
  return arg !== null;
}
