/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-3",
    "aws_appsync_graphqlEndpoint": "https://cskkxid7ojczpivhubcsdhto5i.appsync-api.eu-west-3.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-3",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-szngrvfd2zgvfpe4bkyl3n664y",
    "aws_cognito_identity_pool_id": "eu-west-3:1c483720-3a89-4821-ba50-430f4e33c34e",
    "aws_cognito_region": "eu-west-3",
    "aws_user_pools_id": "eu-west-3_JE4rjBqNX",
    "aws_user_pools_web_client_id": "4o8jak66ub8gaj2j34h53ceqrb",
    "oauth": {
        "domain": "tgx90utxwtwg-production.auth.eu-west-3.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000,https://visite.leazly.fr,https://www.visite.leazly.fr,https://dev.visite.leazly.fr,https://preproduction.visite.leazly.fr,https://staging.visite.leazly.fr",
        "redirectSignOut": "http://localhost:3000,https://visite.leazly.fr,https://www.visite.leazly.fr,https://dev.visite.leazly.fr,https://preproduction.visite.leazly.fr,https://staging.visite.leazly.fr",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "visitef9d20b207c8942be9343ff644eb475ef132335-production",
    "aws_user_files_s3_bucket_region": "eu-west-3"
};


export default awsmobile;
