import { styled } from "@mui/material";

export const SInputNumberWrapper = styled("div")`
  .SInputNumber {
    vertical-align: center;

    &__content {
      display: flex;
      align-items: center;
      justify-content: center;
      height: ${({ theme }) => theme.rem(40)};

      &__button {
        cursor: pointer;
      }

      input {
        width: 2rem;
        padding-bottom: unset;
        margin: unset;
        font-size: 1rem;
        color: ${({ theme }) => theme.gray700};
        text-align: center;
        border: none;
        border-bottom: unset;
      }
    }
  }
`;
