import { styled } from "@mui/material";

export const RoomItemInputWrapper = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 2.5rem;

  .RoomItemInput {
    &__icon {
      display: flex;
      align-items: center;
    }
  }

  .SText {
    width: 15ch;
    margin-left: 0.5rem;

    .AText--text {
      color: ${({ theme }) => theme.colorBlack};
    }
  }

  .AInput input {
    width: 4rem;
    height: 100%;
    margin-left: 1rem;
    text-align: center;
    border-color: ${({ theme }) => theme.colorBorder};
    border-radius: 0.5rem;

    &:focus::placeholder {
      color: transparent;
    }
  }
`;
