import "@aws-amplify/ui-react/styles.css";
import { Amplify } from "aws-amplify";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import awsExports from "./aws-exports";
import { Authenticator } from "@aws-amplify/ui-react";
import { VisitsProvider } from "./data/useVisits";
import { router } from "./router";
import { photo } from "./data/photoStore";

import "./locales/i18n";
import { ThemeProvider } from "@mui/material";
import theme from "./utils/theme";

awsExports.oauth.redirectSignIn = `${window.location.origin}`;
awsExports.oauth.redirectSignOut = `${window.location.origin}`;
Amplify.configure(awsExports);
photo.ready().then(() =>
  createRoot(document.getElementById("root")!).render(
    <StrictMode>
    <Authenticator.Provider>
      <VisitsProvider>
        <ThemeProvider theme={theme}>
          <RouterProvider router={router} />
        </ThemeProvider>
      </VisitsProvider>
    </Authenticator.Provider>
  </StrictMode>
  )
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
