import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { StepFormContainer } from "../StepFormContainer";
import { StepFC } from "./typings";

import {
  Alert,
  AlertTitle,
  Box,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import {
  ACCESS_STEP_TYPE,
  ACCESS_TYPE,
  ACCES_STEP_DATA,
} from "../../utils/enum";
import { AccessStep, ApartmentData, Visit } from "../../models";
import { AccessCreationWrapper } from "./Access.style";
import { DataStore } from "aws-amplify";

const FINAL_ACCESS_STEP = "accédez à l'appartement";

export const Access: StepFC = ({ visit, next, previous, current }) => {
  const theme = useTheme();
  const parentRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [steps, setSteps] = useState<Record<string, any>>({});
  const [isComplete, setIsComplete] = useState(false);
  const { t } = useTranslation("translation", {
    keyPrefix: "access",
  });
  const { origin, updated } = visit;
  const address = updated?.apartment?.address || origin?.apartment.address;
  const city = updated?.apartment?.city || origin?.apartment.city;
  const postalCode =
    updated?.apartment?.postalCode || origin?.apartment.postalCode;
  const fullAddress = `${address} ${postalCode} ${city}`;

  const initialValue =
    updated?.apartment?.accesses ?? origin?.apartment?.accesses;

  const loadExistingAccess = () => {
    if (initialValue) {
      const objectSteps = initialValue.reduce((acc: any, step: any) => {
        return {
          ...acc,
          [step.position]: step,
        };
      }, {});

      setSteps(objectSteps);
      setLoading(false);
      setIsComplete(true);
    }
  };

  const initInitalStep = () => {
    if (!Object.values(steps).length && !initialValue) {
      setSteps({
        "0": {
          type: ACCESS_STEP_TYPE.FIXED,
          value: `${t(`steps.initial.start.${ACCESS_TYPE.FOOT}`, {
            fullAddress: `${fullAddress},`,
          })}`,
        },
        "1": {
          type: ACCESS_STEP_TYPE.ACTION,
          value: null,
        },
        "2": {
          type: ACCESS_STEP_TYPE.CUSTOM,
          value: "",
        },
        "3": {
          type: ACCESS_STEP_TYPE.FIXED,
          value: t("steps.initial.with"),
        },
        "4": {
          type: ACCESS_STEP_TYPE.ACCESS,
          value: null,
        },
        "5": {
          type: ACCESS_STEP_TYPE.CUSTOM,
          value: "",
        },
      });
    }
  };

  const resizeInput = () => {
    if (!parentRef.current) return;

    parentRef.current.querySelectorAll("input").forEach((input) => {
      const hide = document.getElementById("hidden-typography");
      const { value, placeholder } = input;
      const { fontSize, fontWeight, padding } = window.getComputedStyle(input);

      if (hide) {
        hide.style.fontSize = fontSize;
        hide.style.fontWeight = fontWeight;
        hide.style.padding = padding;
        hide.textContent = value !== "" ? value : placeholder;
        input.style.width = `${hide.offsetWidth}px`;
      }
    });
  };

  useEffect(() => {
    initInitalStep();
    loadExistingAccess();
    setTimeout(() => {
      resizeInput();
    }, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visit.id, current]); // Don't touch to this, adding the missing dependencies mentionnent in the TS warning will trigger a infinite render of the component, making the whole app freezing

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const inputs = event.currentTarget.elements;

    return DataStore.save(
      Visit.copyOf(visit, (draft) => {
        if (!draft.updated) {
          draft.updated = {};
        }

        if (!draft.updated.steps) {
          draft.updated.steps = {};
        }

        if (!draft.updated.apartment) {
          draft.updated.apartment = {};
        }

        const formatedSteps = Object.keys(steps).map((key) => ({
          position: Number(key),
          type: steps[key].type,
          value: steps[key].value,
        }));

        draft.updated.apartment.accesses = formatedSteps;
        draft.updated.apartment.doorOpeningInstructions = (
          inputs as any
        ).doorOpeningInstructions.value;
        draft.updated.steps.access = true;
      })
    ).then(() => {
      navigate(`?step=${next}`, {
        relative: "path",
      });
    });
  };

  const accessStepActions = ACCES_STEP_DATA.filter(
    (accessStepData) =>
      accessStepData.accessType === ACCESS_TYPE.FOOT &&
      accessStepData.stepType === ACCESS_STEP_TYPE.ACTION
  );

  const accessStepAccesses = ACCES_STEP_DATA.filter(
    (accessStepData) =>
      accessStepData.accessType === ACCESS_TYPE.FOOT &&
      accessStepData.stepType === ACCESS_STEP_TYPE.ACCESS
  );

  const accessStepLocations = ACCES_STEP_DATA.filter(
    (accessStepData) =>
      accessStepData.accessType === ACCESS_TYPE.FOOT &&
      accessStepData.stepType === ACCESS_STEP_TYPE.LOCATION
  );

  const extractValue = <T extends keyof ApartmentData>(
    key: T
  ): ApartmentData[T] => {
    return updated?.apartment?.[key] ?? origin?.apartment?.[key];
  };

  const defaultValue = {
    doorOpeningInstructions: extractValue("doorOpeningInstructions"),
  };

  const stepValue = (position: number) => {
    return (steps[position] && steps[position].value) || "";
  };

  const formatOptions = (accessSteps: { code: string; accessType: string }[]) =>
    accessSteps.map((accessStep, index) => (
      <MenuItem key={`${index}_${accessStep.code}`} value={accessStep.code}>
        {accessStep.code}
      </MenuItem>
    ));

  const generateOptionsData = () => {
    return {
      accessStepActionsOptions: formatOptions(accessStepActions),
      accessStepAccessesOptions: formatOptions(accessStepAccesses),
      accessStepLocationsOptions: formatOptions(accessStepLocations),
    };
  };

  const countStepsByType = (steps: Record<string, AccessStep>) => {
    return Object.keys(steps).reduce(
      (acc, key) => {
        const currentType = steps[key].type;

        acc.isFinalStepSelected =
          acc.isFinalStepSelected ||
          (steps[key].type === ACCESS_STEP_TYPE.ACTION &&
            steps[key].value === FINAL_ACCESS_STEP);

        if (steps[key].value && currentType) {
          return { ...acc, [currentType]: acc[currentType] + 1 };
        }

        return { ...acc };
      },
      {
        action: 0,
        access: 0,
        custom: 0,
        fixed: 0,
        isFinalStepSelected: false,
        location: 0,
      } as Record<string, any>
    );
  };

  const checkStepsCompletion = (steps: Record<string, AccessStep>) => {
    const neededSteps = (Object.values(steps) as Partial<AccessStep>[]).filter(
      ({ type }) => type !== "custom" && type !== "fixed"
    );
    const isEveryNeededStepFilled = neededSteps.every((step) => step.value);
    const counts = countStepsByType(steps);

    return counts.isFinalStepSelected && isEveryNeededStepFilled;
  };

  const shouldAddLoop = (steps: Record<string, AccessStep>) => {
    const counts = countStepsByType(steps);
    const neededSteps = (Object.values(steps) as Partial<AccessStep>[]).filter(
      ({ type }) => type !== "custom" && type !== "fixed"
    );

    const isEveryNeededStepFilled = neededSteps.every((step) => step.value);

    const isFirstLoop = Object.keys(steps).length <= 6;

    return (
      isEveryNeededStepFilled &&
      counts.action === counts.access &&
      (isFirstLoop || counts.access - 1 === counts.location) &&
      !counts.isFinalStepSelected
    );
  };

  const addNewLoop = (steps: Record<string, AccessStep>) => {
    const maxPosition = Math.max(
      ...Object.keys(steps).map((key) => Number(key))
    );

    const numberOfLoop = (maxPosition - 5) / 8;

    return {
      ...steps,
      [maxPosition + 1]: {
        type: ACCESS_STEP_TYPE.FIXED,
        value: t(`steps.loop.${numberOfLoop % 2 === 0 ? "start" : "once"}`),
      },
      [maxPosition + 2]: {
        type: ACCESS_STEP_TYPE.LOCATION,
        value: null,
      },
      [maxPosition + 3]: {
        type: ACCESS_STEP_TYPE.CUSTOM,
        value: "",
      },
      [maxPosition + 4]: {
        type: ACCESS_STEP_TYPE.ACTION,
        value: null,
      },
      [maxPosition + 5]: {
        type: ACCESS_STEP_TYPE.CUSTOM,
        value: "",
      },
      [maxPosition + 6]: {
        type: ACCESS_STEP_TYPE.FIXED,
        value: t("steps.loop.with"),
      },
      [maxPosition + 7]: {
        type: ACCESS_STEP_TYPE.ACCESS,
        value: null,
      },
      [maxPosition + 8]: {
        type: ACCESS_STEP_TYPE.CUSTOM,
        value: "",
      },
    };
  };

  const handleSelectChange =
    (position: number) => (event: SelectChangeEvent<any>) => {
      let newSteps = {
        ...steps,
        [position.toString()]: {
          type: event.target.name,
          value: event.target.value,
        },
      };

      let loop = 0;
      let loopEdited: number | null = null;
      const allPositions = Object.keys(newSteps)
        .map((key) => Number(key))
        .slice(6);

      if (event.target.value === null) {
        allPositions.forEach((positionLoop, index) => {
          if (index && index % 8 === 0) {
            loop += 1;
          }

          if (positionLoop === position) {
            loopEdited = loop;
          }

          if (position < 6 || (loopEdited !== null && loop > loopEdited)) {
            delete newSteps[positionLoop];
          }
        });
      }

      if (
        event.target.value === FINAL_ACCESS_STEP &&
        position <=
          Math.max(...Object.keys(newSteps).map((key) => Number(key))) - 1
      ) {
        Object.keys(newSteps).forEach((stepPosition) => {
          if (Number(stepPosition) > position + 4) {
            delete newSteps[stepPosition];
          }
        });
      }

      if (shouldAddLoop(newSteps)) {
        newSteps = addNewLoop(newSteps);
      }

      const isComplete = checkStepsCompletion(newSteps);

      setSteps(newSteps);
      setIsComplete(isComplete);
    };

  const handleInputChange =
    (position: number) => (event: ChangeEvent<HTMLInputElement>) => {
      const { name, value, placeholder } = event.target;
      const hide = document.getElementById("hidden-typography");
      const { fontSize, fontWeight } = window.getComputedStyle(event.target);

      if (hide) {
        hide.style.fontSize = fontSize;
        hide.style.fontWeight = fontWeight;
        hide.textContent = value !== "" ? value : placeholder;
        event.target.style.width = `${hide.offsetWidth}px`;
        setSteps((prevState) => ({
          ...prevState,
          [position.toString()]: {
            type: name,
            value: value,
          },
        }));
      }
    };

  const RenderInitialSteps = () => {
    if (Object.keys(steps).length < 1) return null;

    const optionsData = generateOptionsData();

    return (
      <Box component="div" className="AccessCreation__editzone__loop">
        <Typography
          id="hidden-typography"
          className="AccessCreation__hide-typography"
        />
        <Typography>
          {t(`steps.initial.start.${ACCESS_TYPE.FOOT}`, {
            fullAddress: `${fullAddress},`,
          })}
        </Typography>
        <FormControl variant={!stepValue(1) ? undefined : "standard"}>
          <Select
            name="action"
            value={stepValue(1)}
            onChange={handleSelectChange(1)}
            inputProps={{ "aria-label": "Without label" }}
            displayEmpty
            renderValue={(value) => (value ? value : "+")}
            className={!stepValue(1) ? "AccessCreation__button" : ""}
          >
            {optionsData.accessStepActionsOptions}
          </Select>
        </FormControl>
        <TextField
          autoCapitalize="none"
          name="custom"
          value={stepValue(2)}
          onChange={handleInputChange(2)}
          placeholder={t("freeText") || ""}
          variant="standard"
        />
        <Typography>{t("steps.initial.with")}</Typography>
        <FormControl variant={!stepValue(4) ? undefined : "standard"}>
          <Select
            displayEmpty
            name="access"
            value={stepValue(4)}
            onChange={handleSelectChange(4)}
            renderValue={(value) => (value ? value : "+")}
            className={!stepValue(4) ? "AccessCreation__button" : ""}
          >
            {optionsData.accessStepAccessesOptions}
          </Select>
        </FormControl>
        <FormControl>
          <TextField
            autoCapitalize="none"
            name="custom"
            value={stepValue(5)}
            onChange={handleInputChange(5)}
            placeholder={t("freeText") || ""}
            variant="standard"
          />
        </FormControl>
      </Box>
    );
  };

  const renderLoops = () => {
    let loops: any[] = [];
    const maxPosition = Math.max(
      ...Object.keys(steps || {}).map((key) => Number(key))
    );

    if (maxPosition < 7) {
      return null;
    }

    const optionsData = generateOptionsData();

    for (let pos = 7; pos < maxPosition; pos += 8) {
      const numberOfLoop = (pos - 7) / 8;

      const newLoop = (
        <Box key={(numberOfLoop + 1).toString()}>
          <Divider variant="middle" sx={{ marginY: theme.spacing(1) }}>
            {(numberOfLoop + 1).toString()}
          </Divider>
          <div key={`loop-${pos}`} className="AccessCreation__editzone__loop">
            <Typography>
              {t(`steps.loop.${numberOfLoop % 2 === 0 ? "start" : "once"}`)}
            </Typography>
            <FormControl variant={!stepValue(pos) ? undefined : "standard"}>
              <Select
                name="location"
                value={stepValue(pos)}
                onChange={handleSelectChange(pos)}
                displayEmpty
                renderValue={(value) => (value ? value : "+")}
                className={!stepValue(pos) ? "AccessCreation__button" : ""}
              >
                {optionsData.accessStepLocationsOptions}
              </Select>
            </FormControl>
            <TextField
              autoCapitalize="none"
              name="custom"
              value={steps[pos + 1].value}
              onChange={handleInputChange(pos + 1)}
              placeholder={t("freeText") || ""}
              variant="standard"
              sx={{ "& .MuiInputBase-root input": { width: "75px" } }}
            />
            <Typography>,</Typography>
            <FormControl variant={!stepValue(pos + 2) ? undefined : "standard"}>
              <Select
                name="action"
                value={stepValue(pos + 2)}
                onChange={handleSelectChange(pos + 2)}
                displayEmpty
                renderValue={(value) => (value ? value : "+")}
                className={!stepValue(pos + 2) ? "AccessCreation__button" : ""}
              >
                {optionsData.accessStepActionsOptions}
              </Select>
            </FormControl>
            <TextField
              autoCapitalize="none"
              name="custom"
              value={steps[pos + 3].value}
              onChange={handleInputChange(pos + 3)}
              placeholder={t("freeText") || ""}
              variant="standard"
              sx={{ "& .MuiInputBase-root input": { width: "75px" } }}
            />
            <Typography>{t("steps.loop.with")}</Typography>
            <FormControl variant={!stepValue(pos + 5) ? undefined : "standard"}>
              <Select
                value={stepValue(pos + 5)}
                name="access"
                onChange={handleSelectChange(pos + 5)}
                displayEmpty
                renderValue={(value) => (value ? value : "+")}
                className={!stepValue(pos + 5) ? "AccessCreation__button" : ""}
              >
                {optionsData.accessStepAccessesOptions}
              </Select>
            </FormControl>
            <TextField
              autoCapitalize="none"
              name="custom"
              value={steps[pos + 6].value}
              onChange={handleInputChange(pos + 6)}
              placeholder={t("freeText") || ""}
              variant="standard"
              sx={{
                "& .MuiInputBase-root input": {
                  width: "75px",
                  borderBottomStyle: "dotted",
                },
              }}
            />
          </div>
        </Box>
      );

      loops = [...loops, newLoop];
    }

    return loops;
  };

  return (
    <StepFormContainer
      previous={previous}
      onSubmit={handleSubmit}
      title={t("title")}
      disabled={!isComplete}
      visit={visit}
    >
      <Alert
        severity="warning"
        icon={<WarningIcon />}
        sx={{
          "& .MuiAlert-icon": { alignItems: "center" },
          borderRadius: 3,
          marginBottom: 2,
        }}
      >
        <AlertTitle sx={{ fontWeight: 600, fontSize: "0.9rem" }}>
          {t("alert.title")}
        </AlertTitle>
        <Typography variant="caption" sx={{ letterSpacing: 0, lineHeight: 0 }}>
          {t("alert.subtitle")}
        </Typography>
      </Alert>
      <TextField
        id="filled-multiline-flexible"
        label={t("doorOpeningInstructions")}
        name="doorOpeningInstructions"
        defaultValue={defaultValue.doorOpeningInstructions}
        multiline
        rows={3}
        placeholder={t("doorOpeningInstructionsPlaceholder") || ""}
        InputLabelProps={{ shrink: true }}
      />
      <AccessCreationWrapper
        ref={parentRef}
        sx={{
          "& .MuiInputBase-root": {
            lineHeight: "1.5em",
            marginRight: 1,
            color: theme.colorSecondary,
          },
          "& .MuiInputBase-input": {
            height: "1.5em",
          },
          "& .MuiTypography-root": {
            marginRight: 1,
          },
        }}
      >
        <Typography className="AccessCreation__title">
          {t("types.foot")}
        </Typography>
        <Card variant="outlined">
          <CardContent>
            <div className="AccessCreation__editzone__container">
              {loading && (
                <div className="AccessCreation__editzone__loader">
                  <CircularProgress />
                </div>
              )}
              {loading}
              {!loading && (
                <>
                  {RenderInitialSteps()}
                  {renderLoops()}
                </>
              )}
            </div>
          </CardContent>
        </Card>
      </AccessCreationWrapper>
    </StepFormContainer>
  );
};
