import { styled } from "@mui/material";

export const EquipmentListWrapper = styled("div")`
  display: grid;
  width: 100%;
  max-width: ${({ theme }) => theme.rem(800)};
  margin: auto;
  gap: 0.8rem;
  grid-template-columns: repeat(2, calc((100% / 3) - 0.8rem)) repeat(
      auto-fill,
      calc((100% / 3) - 0.8rem)
    );
  margin-bottom: 1rem;
  justify-content: center;
`;
