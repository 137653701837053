import React, { useState } from "react";
import { StepFC } from "./typings";
import { Room, Visit } from "../../models";
import { ROOM_TYPE } from "../../utils/enum";
import { StepFormContainer } from "../StepFormContainer";
import RoomsComponent from "../Rooms/RoomsComponent";
import { useTranslation } from "react-i18next";
import { DataStore } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { Alert, AlertTitle, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export const Rooms: StepFC = ({ visit, previous, current, next }) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "rooms",
  });
  const navigate = useNavigate();

  const [rooms, setRooms] = useState<Room[]>(
    visit.updated?.apartment?.rooms || visit.origin?.apartment?.rooms || []
  );

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    return DataStore.save(
      Visit.copyOf(visit, (draft) => {
        if (!draft.updated) {
          draft.updated = {};
        }

        if (!draft.updated.steps) {
          draft.updated.steps = {};
        }

        if (!draft.updated.apartment) {
          draft.updated.apartment = {};
        }

        draft.updated.steps.rooms = true;
        draft.updated.apartment.rooms = rooms;
      })
    ).then(() => {
      navigate(`?step=${next}`, {
        relative: "path",
      });
    });
  };

  const handleChange = (updatedRooms: any, roomType: string) => {
    setRooms([
      ...rooms.filter((room) => room.type !== roomType),
      ...updatedRooms,
    ]);
  };

  const addRoom = (roomType: any) => {
    const newRoom = {
      items: [],
      type: roomType,
    };
    setRooms([...rooms, newRoom]);
  };

  const deleteRoom = (roomType: any) => {
    const tmp = rooms.map((room) => room.type);

    setRooms([
      ...rooms.reduce((acc: any, room, index) => {
        if (index === tmp.lastIndexOf(roomType)) {
          return acc;
        }

        return [...acc, room];
      }, []),
    ]);
  };

  const shouldDisabled =
    rooms.length === 0 ||
    rooms.reduce((acc, room) => {
      return acc || room.items?.length === 0;
    }, false);

  return (
    <StepFormContainer
      previous={previous}
      onSubmit={handleSubmit}
      title={t("title")}
      disabled={shouldDisabled}
      visit={visit}
    >
      <Alert
        severity="warning"
        icon={<InfoOutlinedIcon sx={{ color: "#536B80" }} />}
        sx={{
          "& .MuiAlert-icon": { alignItems: "center" },
          borderRadius: 3,
          marginBottom: 2,
          bgcolor: "#F2F2F2",
          color: "#536B80",
        }}
      >
        <AlertTitle sx={{ fontWeight: 600, fontSize: "0.9rem" }}>
          {t("alert.title")}
        </AlertTitle>
        <Typography variant="caption" sx={{ letterSpacing: 0, lineHeight: 0 }}>
          {t("alert.subtitle")}
        </Typography>
      </Alert>
      {Object.values(ROOM_TYPE).map((roomType) => (
        <RoomsComponent
          key={roomType}
          title={t(`${roomType}`)}
          roomsTitle={t(`${roomType}`)}
          roomType={roomType}
          onChange={handleChange}
          addRoom={addRoom}
          deleteRoom={deleteRoom}
          rooms={rooms.filter((room) => room.type === roomType) as any}
          collapsible
        />
      ))}
    </StepFormContainer>
  );
};
