import React, { useEffect, useMemo, useState } from "react";

import Room from "./components/Room/Room";

import { Divider, Typography, styled } from "@mui/material";
import SInputNumber from "./components/SInputNumber/SInputNumber";

export const ROOM_TYPE = {
  LIVINGROOM: "livingroom",
  BEDROOM: "bedroom",
  BATHROOM: "bathroom",
} as const;

export const ROOM_ITEM_TYPE = {
  BATH: "bath",
  BED_140: "bed-140",
  BED_160: "bed-160",
  BED_180: "bed-180",
  BED_SOLO: "bed-solo",
  BED_BUNK: "bed-bunk",
  BED_COUCH: "bed-couch",
  SHOWER: "shower",
  TOILETS: "toilets",
};

export const ROOM_ITEM_BY_ROOM = {
  [ROOM_TYPE.BEDROOM]: [
    ROOM_ITEM_TYPE.BED_140,
    ROOM_ITEM_TYPE.BED_160,
    ROOM_ITEM_TYPE.BED_180,
    ROOM_ITEM_TYPE.BED_BUNK,
    ROOM_ITEM_TYPE.BED_SOLO,
    ROOM_ITEM_TYPE.BED_COUCH,
  ],
  [ROOM_TYPE.BATHROOM]: [
    ROOM_ITEM_TYPE.BATH,
    ROOM_ITEM_TYPE.SHOWER,
    ROOM_ITEM_TYPE.TOILETS,
  ],
  [ROOM_TYPE.LIVINGROOM]: [
    ROOM_ITEM_TYPE.BED_140,
    ROOM_ITEM_TYPE.BED_160,
    ROOM_ITEM_TYPE.BED_180,
    ROOM_ITEM_TYPE.BED_BUNK,
    ROOM_ITEM_TYPE.BED_SOLO,
    ROOM_ITEM_TYPE.BED_COUCH,
  ],
};

export type RoomType = {
  items: Record<(typeof ROOM_ITEM_TYPE)[keyof typeof ROOM_ITEM_TYPE], number>;
  type: (typeof ROOM_TYPE)[keyof typeof ROOM_TYPE];
};

export type RoomLloyd = {
  id?: number;
  items: Array<{ type: string }>;
  type: (typeof ROOM_TYPE)[keyof typeof ROOM_TYPE];
};

export function roomSetter(rooms: RoomLloyd[]): RoomType[] {
  return (rooms || []).map((room) => ({
    ...room,
    items: room.items.reduce((acc, item: any) => {
      return { ...acc, [item.type || item]: (acc[item.type || item] || 0) + 1 };
    }, {} as RoomType["items"]),
  }));
}

export interface RoomsProps {
  title: string;
  roomsTitle: string;
  rooms?: RoomLloyd[];
  roomType: "livingroom" | "bedroom" | "bathroom";
  onChange: (rooms: RoomLloyd[], roomType: string) => any;
  addRoom: (roomType: string) => any;
  deleteRoom: (roomType: string) => any;
  collapsible?: boolean;
  /**
   * allow to close all rooms from outside (e.g: on form validation)
   */
  collapsed?: boolean;
}

const RoomsContainer = styled("div")`
  width: 100%;
  max-width: ${({ theme }) => theme.rem(500)};
  padding: 1.5rem;
  margin: auto;
  border: ${({ theme }) => `${theme.borderWidth} solid ${theme.colorBorder}`};
  border-radius: 0.8rem;
  margin-bottom: ${({ theme }) => theme.spacing(2)};

  .Rooms__header {
    display: flex;
    align-items: center;

    &__title {
      flex: 1;
    }
  }
`;

export default function RoomsComponent({
  title,
  roomsTitle,
  roomType,
  onChange,
  rooms = [],
  addRoom,
  deleteRoom,
  collapsible,
  collapsed,
}: RoomsProps) {
  const [firstCreatedRoom, setFirstCreatedRoom] = useState(rooms.length);
  const roomsType = useMemo(() => roomSetter(rooms), [rooms]);
  const handleRoomChange = (roomIndex: number) => (room: RoomType) => {
    const tmpRooms = [...roomsType];
    tmpRooms[roomIndex] = room;

    onChange(
      tmpRooms.map(
        (room) =>
          ({
            ...room,
            items: Object.entries(room.items)
              .map(([key, value]) => new Array(value).fill(key))
              .flat(),
          } as RoomLloyd)
      ),
      roomType
    );
  };

  const handleNumberChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    value: number
  ) => {
    if (value > rooms.length) {
      addRoom(roomType);
    } else {
      deleteRoom(roomType);
      if (firstCreatedRoom >= rooms.length)
        setFirstCreatedRoom(rooms.length - 1);
    }
  };

  const shouldCollapseRoom = (index: number) => {
    let isNewRoom = index >= rooms.length;
    if (index >= firstCreatedRoom) isNewRoom = true;

    if (collapsed === undefined) {
      return isNewRoom ? false : undefined;
    }

    return collapsed;
  };

  useEffect(() => {
    setFirstCreatedRoom(rooms.length);
  }, [collapsed, rooms.length]);

  return (
    <RoomsContainer>
      <div className="Rooms__header">
        <Typography
          className="Rooms__header__title"
          sx={{
            background:
              "linear-gradient(89.73deg, #2D3282 -6.42%, #EF486B 102.07%)",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            width: "fit-content",
          }}
          fontSize="1.2rem"
          fontWeight={400}
        >
          {title}
        </Typography>
        <SInputNumber value={rooms.length} onChange={handleNumberChange} />
      </div>
      {rooms.length > 0 && <Divider sx={{ marginY: "1rem" }} />}
      {rooms.length > 0 &&
        roomsType.map((room, index) => {
          return (
            <Room
              key={String(index)}
              title={`${roomsTitle} ${index + 1}`}
              roomType={roomType}
              room={room}
              isFirst={index === 0}
              onChange={handleRoomChange(index)}
              collapsible={collapsible}
              collapsed={shouldCollapseRoom(index)}
            />
          );
        })}
    </RoomsContainer>
  );
}
