export const STATUS = {
  PENDING: 1,
  ACTIVE: 2,
  ARCHIVED: 3,
};

export const ROLE = {
  ADMIN: "admin",
  PROSPECT: "prospect",
  SALE: "sale",
  OPERATIONAL: "operational",
  CONTRACTOR: "contractor",
  CONTRACTOR_ADMIN: "contractor-admin",
  TECHNICAL: "technical",
};

export const PROSPECT_STATUS = {
  NEW: "new",
  PHONE_CALL: "phone-call",
  VISIT_SCHEDULED: "visit-scheduled",
  WAIT_DOCUMENTS: "wait-documents",
  TO_RECONTACT: "to-recontact",
  TO_SIGN: "to-sign",
  SIGNED: "signed",
  REJECTED: "rejected",
  WAIT_FIRST_CALL: "wait-first-call",
  WAIT_VISIT: "wait-visit",
  MANDATE_TO_SENT: "mandate-to-sent",
};

export const PROSPECT_STATUS_BEFORE_VISITE = [
  "new",
  "phone-call",
  "visit-scheduled",
  "wait-first-call",
  "wait-visit",
];

export const LEAVING_STATUS = {
  INIT: "init",
  SIGNED: "signed",
  ARCHIVED: "archived",
  CLOSED: "closed",
};

export const CHECKOUT_STATUS = {
  NO_PROBLEM: "no-problem",
  MINOR_PROBLEM: "minor-problem",
  MAJOR_PROBLEM: "major-problem",
};

export const DOCUMENT = {
  ID: "identityDocuments",
  RIB: "rib",
  PROOF: "proofDocument",
  AUTHORIZATION: "authorizationDocument",
  DEPARTURE: "departureDocument",
  RECEIPTS: "receipts",
  USAGE: "usageDocument",
} as const;

export const EVENT_TYPE = {
  CREATE: "create",
  UPDATE: "update",
  CHANGE_SALE: "change-sale",
  CHANGE_STATUS: "change-status",
  COMMENT: "comment",
  ADD_DOCUMENT: "add-document",
  REMOVE_DOCUMENT: "remove-document",
  GENERATE_MANDATE: "generate-mandate",
  GENERATE_MANDATE_MANUAL: "generate-mandate-manual",
};

export const OWNERSHIP = {
  OWNER: "owner",
  TENANT: "tenant",
};

export const RESIDENCE_TYPE = {
  PRIMARY: "primary",
  SECONDARY: "secondary",
};

export const FORMAT_TYPE = {
  JSON: "application/json",
  CSV: "text/csv",
  XLSX: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
};

export const HOUSING_TYPE = {
  HOUSE: "house",
  APARTMENT: "apartment",
};

export const DELIVERY_CONTRACTORS = {
  LA_POSTE: "la-poste",
  STUART: "stuart",
};

export const KEY_SETUP_STATUS = {
  INIT: "init",
  READY: "ready",
  DEPOSITED: "deposited",
} as const;

export const KEY_UN_SETUP_STATUS = {
  INIT: "init",
  READY: "ready",
  DONE: "done",
};

export const APARTMENT_KEY_DENOMINATION = {
  GUEST: "guest",
  AGENCY: "agency",
  CLEANING: "cleaning",
} as const;

export const APARTMENT_KEY_TYPE = {
  KEYCAFE: "keycafe",
  KEYNEST: "keynest",
  OTHERS: "others",
} as const;

export const GENDER = {
  MALE: "male",
  FEMALE: "female",
};

export const PHOTO_CONTRACT_STATUS = {
  TO_SCHEDULE: "to-schedule",
  SCHEDULED: "scheduled",
  DELIVERED: "delivered",
  CANCELED: "canceled",
};

export const INVENTORY_TYPE = {
  OUT: "out",
  IN: "in",
  OUT_REVIEW: "out-review",
  IN_REVIEW: "in-review",
  OUT_VALIDATION: "out-validation",
};

export const INVENTORY_STATUS = {
  INIT: "init",
  TO_SIGN: "to-sign",
  DONE: "done",
};

export const ROOM_TYPE = {
  LIVINGROOM: "livingroom",
  BEDROOM: "bedroom",
  BATHROOM: "bathroom",
} as const;

export const KEY_PROCESS_TYPE = {
  SETUP: "setup",
  UNSETUP: "unsetup",
};

export const APARTMENT_KEY_STATUS = {
  WAITING_DROPOFF: "waiting-dropoff",
  IN_USE: "in-use",
  STORED: "stored",
} as const;

export const BED_ROOM_ITEM_TYPE = {
  BED_140: "bed-140",
  BED_160: "bed-160",
  BED_180: "bed-180",
  BED_BUNK: "bed-bunk",
  BED_COUCH: "bed-couch",
  BED_SOLO: "bed-solo",
} as const;

export const LIVING_ROOM_ITEM_TYPE = {
  BED_140: "bed-140",
  BED_160: "bed-160",
  BED_180: "bed-180",
  BED_BUNK: "bed-bunk",
  BED_COUCH: "bed-couch",
  BED_SOLO: "bed-solo",
} as const;

export const BATH_ROOM_ITEM_TYPE = {
  SHOWER: "shower",
  TOILETS: "toilets",
  BATH: "bath",
} as const;

export const ROOM_ITEM_TYPE = {
  ...BATH_ROOM_ITEM_TYPE,
  ...LIVING_ROOM_ITEM_TYPE,
  ...BATH_ROOM_ITEM_TYPE,
} as const;

export const ROOM_ITEM_BY_ROOM = {
  [ROOM_TYPE.BEDROOM]: [
    ROOM_ITEM_TYPE.BED_140,
    ROOM_ITEM_TYPE.BED_160,
    ROOM_ITEM_TYPE.BED_180,
    ROOM_ITEM_TYPE.BED_BUNK,
    ROOM_ITEM_TYPE.BED_SOLO,
    ROOM_ITEM_TYPE.BED_COUCH,
  ],
  [ROOM_TYPE.BATHROOM]: [
    ROOM_ITEM_TYPE.BATH,
    ROOM_ITEM_TYPE.SHOWER,
    ROOM_ITEM_TYPE.TOILETS,
  ],
  [ROOM_TYPE.LIVINGROOM]: [
    ROOM_ITEM_TYPE.BED_140,
    ROOM_ITEM_TYPE.BED_160,
    ROOM_ITEM_TYPE.BED_180,
    ROOM_ITEM_TYPE.BED_BUNK,
    ROOM_ITEM_TYPE.BED_SOLO,
    ROOM_ITEM_TYPE.BED_COUCH,
  ],
};

export const ACCESS_TYPE = {
  FOOT: "foot",
  CAR: "car",
} as const;

export const ACCESS_STEP_TYPE = {
  ACCESS: "access",
  ACTION: "action",
  CUSTOM: "custom",
  FIXED: "fixed",
  LOCATION: "location",
} as const;

export const LANGUAGES = {
  FR: "FR",
  EN: "EN",
  IT: "IT",
  DE: "DE",
  ES: "ES",
} as const;

export const KEYWORDS = [
  { id: 1, code: "beau", type: "apartment" },
  { id: 2, code: "luxueux", type: "apartment" },
  { id: 3, code: "cozy", type: "apartment" },
  { id: 4, code: "ancien", type: "apartment" },
  { id: 5, code: "charmant", type: "apartment" },
  { id: 6, code: "atypique", type: "apartment" },
  { id: 7, code: "lumineux", type: "apartment" },
  { id: 8, code: "chaleureux", type: "apartment" },
  { id: 9, code: "spacieux", type: "apartment" },
  { id: 10, code: "confortable", type: "apartment" },
  { id: 11, code: "somptueux", type: "apartment" },
  { id: 12, code: "élégant", type: "apartment" },
  { id: 13, code: "sobre", type: "apartment" },
  { id: 14, code: "intimiste", type: "apartment" },
  { id: 15, code: "coquet", type: "apartment" },
  { id: 16, code: "accueillant", type: "apartment" },
  { id: 17, code: "cossu", type: "apartment" },
  { id: 18, code: "agréable", type: "apartment" },
  { id: 19, code: "ravissant", type: "apartment" },
  { id: 20, code: "adorable", type: "apartment" },
  { id: 21, code: "vaste", type: "apartment" },
  { id: 22, code: "pittoresque", type: "apartment" },
  { id: 23, code: "haussmanien", type: "building" },
  { id: 24, code: "moderne", type: "building" },
  { id: 25, code: "ancien", type: "building" },
  { id: 26, code: "baroque", type: "building" },
  { id: 27, code: "en pierre de taille", type: "building" },
  { id: 28, code: "dynamique", type: "neighborhood" },
  { id: 29, code: "d’affaires", type: "neighborhood" },
  { id: 30, code: "jeune", type: "neighborhood" },
  { id: 31, code: "branché", type: "neighborhood" },
  { id: 32, code: "familial", type: "neighborhood" },
  { id: 33, code: "calme", type: "neighborhood" },
  { id: 34, code: "historique", type: "neighborhood" },
  { id: 35, code: "touristique", type: "neighborhood" },
  { id: 36, code: "pittoresque", type: "neighborhood" },
  { id: 37, code: "cossu", type: "neighborhood" },
  { id: 38, code: "très recherché", type: "neighborhood" },
  { id: 39, code: "cosmopolite", type: "neighborhood" },
  { id: 40, code: "étudiant", type: "neighborhood" },
  { id: 41, code: "qui ne dort jamais", type: "neighborhood" },
  { id: 42, code: "vivant", type: "neighborhood" },
  { id: 44, code: "animé", type: "neighborhood" },
  { id: 45, code: "multiculturel", type: "neighborhood" },
  { id: 46, code: "bohème", type: "neighborhood" },
  { id: 47, code: "résidentiel", type: "neighborhood" },
  { id: 48, code: "très bien desservi", type: "neighborhood" },
  {
    id: 49,
    code: "proche de nombreux commerces / restaurants",
    type: "neighborhood",
  },
  { id: 50, code: "rue piétonne", type: "surrounding" },
  { id: 51, code: "parcs à proximité", type: "surrounding" },
  { id: 52, code: "design moderne", type: "surrounding" },
  { id: 53, code: "décoration soignée", type: "surrounding" },
  { id: 54, code: "appartement en duplex", type: "surrounding" },
  { id: 55, code: "transports à proximité", type: "surrounding" },
  { id: 56, code: "très belle vue", type: "surrounding" },
  { id: 57, code: "standard", type: "building" },
  { id: 58, code: "typique", type: "building" },
];

export const ACCES_STEP_DATA = [
  {
    code: "du code",
    accessType: "foot",
    stepType: "access",
  },
  {
    code: "du badge",
    accessType: "foot",
    stepType: "access",
  },
  {
    code: "de l'escalier",
    accessType: "foot",
    stepType: "access",
  },
  {
    code: "de la porte",
    accessType: "foot",
    stepType: "access",
  },
  {
    code: "de l'ascenseur",
    accessType: "foot",
    stepType: "access",
  },
  {
    code: "dans la cour",
    accessType: "foot",
    stepType: "location",
  },
  {
    code: "dans le hall",
    accessType: "foot",
    stepType: "location",
  },
  {
    code: "dans le couloir",
    accessType: "foot",
    stepType: "location",
  },
  {
    code: "sur le palier",
    accessType: "foot",
    stepType: "location",
  },
  {
    code: "dans un sas",
    accessType: "foot",
    stepType: "location",
  },
  {
    code: "devant la grille",
    accessType: "foot",
    stepType: "location",
  },
  {
    code: "du parcmètre",
    accessType: "car",
    stepType: "access",
  },
  {
    code: "dans le parking",
    accessType: "car",
    stepType: "location",
  },
  {
    code: "dans une allée",
    accessType: "car",
    stepType: "location",
  },
  {
    code: "ouvrez la porte",
    accessType: "foot",
    stepType: "action",
  },
  {
    code: "accédez à l'appartement",
    accessType: "foot",
    stepType: "action",
  },
  {
    code: "accédez à l'étage",
    accessType: "foot",
    stepType: "action",
  },
  {
    code: "accédez au bâtiment",
    accessType: "foot",
    stepType: "action",
  },
  {
    code: "ouvrez la porte de l'immeuble",
    accessType: "foot",
    stepType: "action",
  },
  {
    code: "de la place",
    accessType: "car",
    stepType: "access",
  },
  {
    code: "ouvrez le portail",
    accessType: "car",
    stepType: "action",
  },
  {
    code: "garez-vous",
    accessType: "car",
    stepType: "action",
  },
  {
    code: "ouvrez le parking",
    accessType: "car",
    stepType: "action",
  },
  {
    code: "au parking",
    accessType: "car",
    stepType: "location",
  },
  {
    code: "accédez à l'immeuble",
    accessType: "car",
    stepType: "action",
  },
  {
    code: "du chemin",
    accessType: "car",
    stepType: "access",
  },
  {
    code: "de la clé",
    accessType: "foot",
    stepType: "access",
  },
];
