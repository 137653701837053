// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const CategoryEnum = {
  "STUDIO": "STUDIO",
  "T1": "T1",
  "T1_BIS": "T1_BIS",
  "T2": "T2",
  "T3": "T3",
  "T4": "T4",
  "T5": "T5",
  "T6": "T6",
  "T7": "T7",
  "OTHERS": "OTHERS"
};

const OwnershipEnum = {
  "TENANT": "TENANT",
  "OWNER": "OWNER"
};

const GenderEnum = {
  "MALE": "MALE",
  "FEMALE": "FEMALE"
};

const HousingEnum = {
  "HOUSE": "HOUSE",
  "APARTMENT": "APARTMENT"
};

const PrerequisiteStatusEnum = {
  "CONFORM": "CONFORM",
  "PENDING": "PENDING",
  "REFUSED": "REFUSED"
};

const { Visit, OriginData, UpdatedData, ClientData, LeavingData, ApartmentData, Steps, Prerequisite, Photo, AccessStep, Access, RentalAgency, Keyword, Room } = initSchema(schema);

export {
  Visit,
  CategoryEnum,
  OwnershipEnum,
  GenderEnum,
  HousingEnum,
  PrerequisiteStatusEnum,
  OriginData,
  UpdatedData,
  ClientData,
  LeavingData,
  ApartmentData,
  Steps,
  Prerequisite,
  Photo,
  AccessStep,
  Access,
  RentalAgency,
  Keyword,
  Room
};