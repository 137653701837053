import React, { useState } from "react";
import { StepFC } from "./typings";
import { StepFormContainer } from "../StepFormContainer";
import { useTranslation } from "react-i18next";
import KeywordsSelector from "../Keywords/KeywordsSelector";
import { KEYWORDS } from "../../utils/enum";
import { DataStore } from "aws-amplify";
import { Visit } from "../../models";
import { useNavigate } from "react-router-dom";

export const Tags: StepFC = ({ visit, previous, next }) => {
  const { t } = useTranslation("translation", { keyPrefix: "tags" });
  const navigate = useNavigate();
  const [isValid, setIsValid] = useState(
    visit.updated?.apartment?.keywords ||
      visit.origin?.apartment.keywords?.length
  );
  const [keywords, setKeywords] = useState(
    visit.updated?.apartment?.keywords ||
      visit.origin?.apartment?.keywords ||
      []
  );

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    return DataStore.save(
      Visit.copyOf(visit, (draft) => {
        if (!draft.updated) {
          draft.updated = {};
        }

        if (!draft.updated.steps) {
          draft.updated.steps = {};
        }

        if (!draft.updated.apartment) {
          draft.updated.apartment = {};
        }

        draft.updated.steps.tags = true;
        draft.updated.apartment.keywords = keywords;
      })
    ).then(() => {
      navigate(`?step=${next}`, {
        relative: "path",
      });
    });
  };

  return (
    <StepFormContainer
      title={t("title")}
      previous={previous}
      onSubmit={handleSubmit}
      disabled={!isValid}
      visit={visit}
    >
      <KeywordsSelector
        categories={["apartment", "building", "neighborhood", "surrounding"]}
        keywords={KEYWORDS}
        onChange={setKeywords}
        selectedIds={keywords}
        editable
        onStatusChange={setIsValid}
      />
    </StepFormContainer>
  );
};
