import React from "react";
import { useTranslation } from "react-i18next";

import {
  EquipmentIcon,
  EquipmentIconColor,
} from "./components/EquipmentIcon/EquipmentIcon";

import { EquipmentWrapper } from "./Equipment.style";
import { Typography } from "@mui/material";

export type EquipmentName =
  | "handicap-accessible"
  | "car-park"
  | "garden"
  | "yard-sight"
  | "barbecue"
  | "jacuzzi"
  | "shared-pool"
  | "private-pool"
  | "blender"
  | "street-sight"
  | "balcony"
  | "television"
  | "coffee-machine"
  | "dryer"
  | "phone"
  | "chimney"
  | "wc"
  | "shower"
  | "mop"
  | "hoover"
  | "bath"
  | "dishwasher"
  | "elevator"
  | "microwave"
  | "oven"
  | "washer"
  | "kettle"
  | "toaster"
  | "hair-dryer"
  | "iron"
  | "curtain"
  | "shutter"
  | "air-conditioner"
  | "freezer"
  | "towel-dryer"
  | "drying-rack"
  | "ironing-board"
  | "fridge"
  | "terrace"
  | "smoke-alarm"
  | "fan";

export interface EquipmentProps {
  name: EquipmentName;
  checked?: boolean;
  disabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  color?: EquipmentIconColor;
}

export default function Equipment({
  name,
  disabled = false,
  checked,
  onChange = () => {},
  color,
  ...props
}: EquipmentProps) {
  const { t } = useTranslation();

  return (
    <EquipmentWrapper
      {...props}
      type="button"
      className={`Equipment ${disabled ? "Equipment--disabled" : ""} ${
        checked ? "Equipment--checked" : ""
      }`}
    >
      <EquipmentIcon name={name} color={color} />
      <Typography color="text.secondary">{t(`equipment.${name}`)}</Typography>

      <input
        disabled={disabled}
        className={`Equipment__input-checkbox ${
          disabled ? "Equipment__input-checkbox--disabled" : ""
        }`}
        type="checkbox"
        name={name}
        checked={checked}
        onChange={onChange}
      />
    </EquipmentWrapper>
  );
}
