import React from "react";
import { StepFormContainer } from "../StepFormContainer";
import { StepFC } from "./typings";
import { Trans, useTranslation } from "react-i18next";
import { Alert, AlertTitle, Box, Typography } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import InsuranceSVG from "../../assets/insurance.svg";
import { useNavigate } from "react-router";
import { DataStore } from "aws-amplify";
import { Visit } from "../../models";

export const Insurance: StepFC = ({ visit, previous, next }) => {
  const navigate = useNavigate();
  const { t } = useTranslation("translation", { keyPrefix: "insurance" });
  const notIncluded = [
    "plants",
    "high-tech",
    "arts",
    "instruments",
    "collection",
    "jewelry",
    "clothes",
    "administratif",
    "locked-door",
  ];

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    return DataStore.save(
      Visit.copyOf(visit, (draft) => {
        if (!draft.updated) {
          draft.updated = {};
        }

        if (!draft.updated.steps) {
          draft.updated.steps = {};
        }

        draft.updated.steps.insurance = true;
      })
    ).then(() =>
      navigate(`?step=${next}`, {
        relative: "path",
      })
    );
  };

  return (
    <StepFormContainer
      previous={previous}
      onSubmit={handleSubmit}
      title={t("title")}
      visit={visit}
    >
      <Alert
        severity="warning"
        icon={<WarningIcon />}
        sx={{ "& .MuiAlert-icon": { alignItems: "center" }, borderRadius: 3 }}
      >
        <AlertTitle sx={{ fontWeight: 600, fontSize: "0.9rem" }}>
          {t("alert.title")}
        </AlertTitle>
        <Typography variant="caption" sx={{ letterSpacing: 0, lineHeight: 0 }}>
          {t("alert.subtitle")}
        </Typography>
      </Alert>
      <Box sx={{ marginY: 5, display: "flex", justifyContent: "center" }}>
        <img src={InsuranceSVG} alt="insurance-svg" />
      </Box>
      <Typography>
        <Trans components={{ strong: <strong /> }}>{t("warning")}</Trans>
      </Typography>
      <Box component="ul">
        {notIncluded.map((element) => (
          <Box key={element} component="li">
            <Typography variant="body2">
              {t(`notInclude.${element}`)}
            </Typography>
          </Box>
        ))}
      </Box>
    </StepFormContainer>
  );
};
