import { Box, Container, useTheme } from "@mui/material";

export const HomePage: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { breakpoints } = useTheme();

  return (
    <Box
      sx={{
        maxWidth: { xs: "100vw", sm: `${breakpoints.values.sm}px` },
        width: { xs: "100vw", sm: `${breakpoints.values.sm}px` },
        minWidth: { xs: "100vw", sm: `${breakpoints.values.sm}px` },
        height: { xs: "100dvh" },
        maxHeight: { xs: "100dvh" },
        display: "flex",
        flexDirection: { xs: "column" /* lg: "row" */ },
        padding: 2,
      }}
    >
      {children}
    </Box>
  );
};

export const Page: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { breakpoints } = useTheme();

  return (
    <Box
      sx={{
        width: { xs: "100vw", sm: `${breakpoints.values.sm}px` },
        minWidth: { xs: "100vw", sm: `${breakpoints.values.sm}px` },
        maxWidth: { xs: "100vw", sm: `${breakpoints.values.sm}px` },
        height: { xs: "100dvh" },
        maxHeight: { xs: "100vh" },
      }}
    >
      <Container sx={{ display: "flex", height: "100%", padding: "1rem" }}>
        {children}
      </Container>
    </Box>
  );
};

export const PageContainer: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Box
      id="PageContainer"
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 0,
      }}
      flexWrap={{ xs: "nowrap", sm: "wrap" }}
      overflow={{ xs: "visible", sm: "scroll" }}
    >
      <>{children}</>
    </Box>
  );
};
