import household_items2x from "../../../../assets/household_items@2x.png";
import { css, styled } from "@mui/material";

const householdItemsElement = (index: number) => css`
  width: 64px;
  height: 64px;
  background-image: url(${household_items2x});
  background-repeat: no-repeat;
  background-position: 0 -${index * 63.8}px;
  transform: scale(0.5);
`;

export const EquipmentIconWrapper = styled("span")`
  &.household_items {
    &--black {
      filter: brightness(0%);
    }

    &--gray {
      filter: grayscale(1);
    }

    &--white {
      filter: brightness(400%);
    }

    &--blue {
      filter: hue-rotate(261deg) saturate(107%) brightness(51%);
    }
  }

  &.household_items-bed,
  &.household_items-bed-140,
  &.household_items-bed-160,
  &.household_items-bed-180 {
    ${householdItemsElement(0)};
  }

  &.household_items-desk {
    ${householdItemsElement(1)};
  }

  &.household_items-convenient {
    ${householdItemsElement(2)};
  }

  &.household_items-towel {
    ${householdItemsElement(3)};
  }

  &.household_items-pillow {
    ${householdItemsElement(4)};
  }

  &.household_items-comforter {
    ${householdItemsElement(5)};
  }

  &.household_items-nightstand {
    ${householdItemsElement(6)};
  }

  &.household_items-bed-couch {
    ${householdItemsElement(7)};
  }

  &.household_items-television {
    ${householdItemsElement(8)};
  }

  &.household_items-chimney {
    ${householdItemsElement(9)};
  }

  &.household_items-stool {
    ${householdItemsElement(10)};
  }

  &.household_items-chair {
    ${householdItemsElement(11)};
  }

  &.household_items-armchair {
    ${householdItemsElement(12)};
  }

  &.household_items-couch {
    ${householdItemsElement(13)};
  }

  &.household_items-table {
    ${householdItemsElement(14)};
  }

  &.household_items-low-table {
    ${householdItemsElement(15)};
  }

  &.household_items-hifi-system {
    ${householdItemsElement(16)};
  }

  &.household_items-projector {
    ${householdItemsElement(17)};
  }

  &.household_items-dvd-player {
    ${householdItemsElement(18)};
  }

  &.household_items-rug {
    ${householdItemsElement(19)};
  }

  &.household_items-tv-stand {
    ${householdItemsElement(20)};
  }

  &.household_items-book-stand {
    ${householdItemsElement(21)};
  }

  &.household_items-wc {
    ${householdItemsElement(22)};
  }

  &.household_items-shower {
    ${householdItemsElement(23)};
  }

  &.household_items-bath {
    ${householdItemsElement(24)};
  }

  &.household_items-towel-dryer {
    ${householdItemsElement(25)};
  }

  &.household_items-drying-rack {
    ${householdItemsElement(26)};
  }

  &.household_items-dryer {
    ${householdItemsElement(27)};
  }

  &.household_items-ironing-board {
    ${householdItemsElement(28)};
  }

  &.household_items-mop {
    ${householdItemsElement(29)};
  }

  &.household_items-hair-dryer {
    ${householdItemsElement(30)};
  }

  &.household_items-hoover {
    ${householdItemsElement(31)};
  }

  &.household_items-iron {
    ${householdItemsElement(32)};
  }

  &.household_items-washer {
    ${householdItemsElement(33)};
  }

  &.household_items-toaster {
    ${householdItemsElement(34)};
  }

  &.household_items-blender {
    ${householdItemsElement(35)};
  }

  &.household_items-dishwasher {
    ${householdItemsElement(36)};
  }

  &.household_items-kettle {
    ${householdItemsElement(37)};
  }

  &.household_items-coffee-machine {
    ${householdItemsElement(38)};
  }

  &.household_items-fridge {
    ${householdItemsElement(39)};
  }

  &.household_items-freezer {
    ${householdItemsElement(40)};
  }

  &.household_items-microwave {
    ${householdItemsElement(41)};
  }

  &.household_items-oven {
    ${householdItemsElement(42)};
  }

  &.household_items-elevator {
    ${householdItemsElement(45)};
  }

  &.household_items-private-pool {
    ${householdItemsElement(46)};
  }

  &.household_items-shared-pool {
    ${householdItemsElement(47)};
  }

  &.household_items-jacuzzi {
    ${householdItemsElement(48)};
  }

  &.household_items-barbecue {
    ${householdItemsElement(49)};
  }

  &.household_items-yard-sight {
    ${householdItemsElement(50)};
  }

  &.household_items-street-sight {
    ${householdItemsElement(51)};
  }

  &.household_items-handicap-accessible {
    ${householdItemsElement(52)};
  }

  &.household_items-terrace {
    ${householdItemsElement(53)};
  }

  &.household_items-shutter {
    ${householdItemsElement(54)};
  }

  &.household_items-curtain {
    ${householdItemsElement(55)};
  }

  &.household_items-fan {
    ${householdItemsElement(56)};
  }

  &.household_items-phone {
    ${householdItemsElement(57)};
  }

  &.household_items-air-conditioner {
    ${householdItemsElement(58)};
  }

  &.household_items-radiator {
    ${householdItemsElement(59)};
  }

  &.household_items-light {
    ${householdItemsElement(60)};
  }

  &.household_items-balcony {
    ${householdItemsElement(61)};
  }

  &.household_items-car-park {
    ${householdItemsElement(62)};
  }

  &.household_items-smoke-alarm {
    ${householdItemsElement(63)};
  }

  &.household_items-bedside-lamp {
    ${householdItemsElement(64)};
  }

  &.household_items-garden {
    ${householdItemsElement(65)};
  }

  &.household_items-bed-solo {
    ${householdItemsElement(66)};
  }

  &.household_items-bed-bunk {
    ${householdItemsElement(67)};
  }
`;
