import { DataStore } from "aws-amplify";
import {
  ClientData,
  GenderEnum,
  LazyClientData,
  OwnershipEnum,
  Visit,
} from "../../models";
import { StepFC } from "./typings";
import { useNavigate } from "react-router-dom";

import {
  FormControlLabel,
  FormLabel,
  RadioGroup,
  TextField,
  Radio,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { StepFormContainer } from "../StepFormContainer";
import { ChangeEvent, useState } from "react";

export const ClientInformations: StepFC = ({ visit, previous, next }) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "clientInformations",
  });
  const navigate = useNavigate();
  const [ownership, setOwnership] = useState(
    visit.updated?.apartment?.ownership ?? visit.origin?.apartment?.ownership
  );
  const [gender, setGender] = useState(
    visit.updated?.client?.gender ?? visit.origin?.client?.gender
  );

  const { origin, updated } = visit;

  const extractValue = (key: keyof ClientData) => {
    return updated?.client?.[key] ?? origin?.client?.[key];
  };

  const clientInformations = {
    lastname: extractValue("lastname"),
    firstname: extractValue("firstname"),
    birthDate: extractValue("birthDate"),
    birthTown: extractValue("birthTown"),
    email: extractValue("email"),
    phoneNumber: extractValue("phoneNumber"),
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = new FormData(event.target as HTMLFormElement);
    data.delete("ownership");
    const inputValue: LazyClientData = Object.fromEntries(data.entries());

    return DataStore.save(
      Visit.copyOf(visit, (draft) => {
        Object.entries(inputValue).forEach(([key, value]) => {
          if (!draft.updated) {
            draft.updated = {};
          }

          if (!draft.updated.client) {
            draft.updated.client = {};
          }

          draft.updated.client[key as unknown as never] = value as never;
        });

        if (!draft.updated) {
          draft.updated = {};
        }

        if (!draft.updated.steps) {
          draft.updated.steps = {};
        }

        if (!draft.updated.client) {
          draft.updated.client = {};
        }

        if (!draft.updated.apartment) {
          draft.updated.apartment = {};
        }

        draft.updated.apartment.ownership = ownership;
        draft.updated.steps.client = true;
      })
    ).then(() => {
      navigate(`?step=${next}`, {
        relative: "path",
      });
    });
  };

  const handleOwnership = (event: ChangeEvent<HTMLInputElement>) => {
    setOwnership(event.target.value as OwnershipEnum);
  };

  const handleGender = (event: ChangeEvent<HTMLInputElement>) => {
    setGender(event.target.value as GenderEnum);
  };

  return (
    <StepFormContainer
      previous={previous}
      onSubmit={handleSubmit}
      title={t("title")}
      visit={visit}
    >
      <FormLabel focused={false} id="gender" sx={{ fontSize: "0.75rem" }}>
        {t("gender")}
      </FormLabel>
      <RadioGroup
        row
        aria-labelledby="gender"
        name="gender"
        value={gender}
        onChange={handleGender}
      >
        <FormControlLabel
          value={GenderEnum["FEMALE"]}
          label={t("genders.female")}
          control={<Radio />}
        />
        <FormControlLabel
          value={GenderEnum["MALE"]}
          label={t("genders.male")}
          control={<Radio />}
        />
      </RadioGroup>
      <TextField
        name="lastname"
        label={t("lastname")}
        defaultValue={clientInformations.lastname}
        required
      />
      <TextField
        name="firstname"
        label={t("firstname")}
        defaultValue={clientInformations.firstname}
        required
      />
      <TextField
        name="birthDate"
        label={t("birthDate")}
        defaultValue={clientInformations.birthDate}
        InputLabelProps={{ shrink: true }}
        type="date"
        sx={{
          "input::-webkit-date-and-time-value": {
            textAlign: "left",
          },
        }}
        required
      />
      <TextField
        name="birthTown"
        label={t("birthTown")}
        defaultValue={clientInformations.birthTown}
        required
      />
      <TextField
        name="email"
        label={t("email")}
        defaultValue={clientInformations.email}
        required
      />
      <TextField
        name="phoneNumber"
        label={t("phoneNumber")}
        defaultValue={clientInformations.phoneNumber}
        required
      />
      <FormLabel focused={false} id="ownership" sx={{ fontSize: "0.75rem" }}>
        {t("status")}
      </FormLabel>
      <RadioGroup
        row
        aria-labelledby="ownership"
        name="ownership"
        value={ownership}
        onChange={handleOwnership}
      >
        <FormControlLabel
          value={OwnershipEnum["OWNER"]}
          label={t("ownership.owner")}
          control={<Radio />}
        />
        <FormControlLabel
          value={OwnershipEnum["TENANT"]}
          label={t("ownership.tenant")}
          control={<Radio />}
        />
      </RadioGroup>
    </StepFormContainer>
  );
};
