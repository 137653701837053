import React from "react";

import Equipment, { EquipmentName } from "../Equipment/Equipment";
import { EquipmentListWrapper } from "./EquipmentList.style";

export interface EquipmentListProps {
  value?: Array<string>;
  onChange?: (evt: any, value: Array<string>) => void;
  disabled?: boolean;
  className?: string;
  name?: string;
  equipments?: Array<EquipmentName>;
}

export const equipmentsDefault: Array<EquipmentName> = [
  "handicap-accessible",
  "car-park",
  "garden",
  "yard-sight",
  "barbecue",
  "jacuzzi",
  "shared-pool",
  "private-pool",
  "blender",
  "street-sight",
  "balcony",
  "television",
  "coffee-machine",
  "dryer",
  "phone",
  "chimney",
  "wc",
  "shower",
  "mop",
  "hoover",
  "bath",
  "dishwasher",
  "elevator",
  "microwave",
  "oven",
  "washer",
  "kettle",
  "toaster",
  "hair-dryer",
  "iron",
  "curtain",
  "shutter",
  "air-conditioner",
  "freezer",
  "towel-dryer",
  "drying-rack",
  "ironing-board",
  "fridge",
  "terrace",
  "smoke-alarm",
  "fan",
];

export default function EquipmentList({
  value = [],
  onChange = () => {},
  disabled = false,
  className = "",
  equipments = equipmentsDefault,
  name,
  ...props
}: EquipmentListProps) {
  const [selected, setSelected] = React.useState(value);

  const handleChange = (evt: any) => {
    const newSelected: Array<string> =
      selected.indexOf(evt.target.name) !== -1
        ? selected.filter((equipment) => evt.target.name !== equipment)
        : [...selected, evt.target.name];

    const event = {
      target: {
        name: name || "equipments",
        value: newSelected,
        type: "equipments",
      },
    };

    setSelected(newSelected);
    return onChange(event, newSelected);
  };

  return (
    <EquipmentListWrapper {...props}>
      {equipments.map((equipment) => (
        <Equipment
          key={equipment}
          name={equipment}
          checked={selected.indexOf(equipment) !== -1}
          disabled={disabled}
          onChange={handleChange}
        />
      ))}
    </EquipmentListWrapper>
  );
}
