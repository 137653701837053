import React from "react";

import { KeywordType } from "../KeywordCategory/KeywordCategory";

import { KeywordBadgeWrapper } from "./KeywordBadge.style";
import { Typography, styled } from "@mui/material";

export interface KeywordBadgeProps {
  keyword: KeywordType;
  selected: boolean;
  editable: boolean;
  onClick: CallableFunction;
}

const StyledTypography = styled(Typography)({
  "&::first-letter": {
    textTransform: "capitalize",
  },
});

export default function KeywordBadge({
  keyword,
  selected,
  editable,
  onClick,
}: KeywordBadgeProps) {
  return (
    <KeywordBadgeWrapper
      type="button"
      onClick={editable ? onClick(keyword) : undefined}
      className={[
        `KeywordBadge__keyword${editable ? "--available" : ""}`,
        `KeywordBadge__keyword${selected ? "--selected" : ""}`,
      ].join(" ")}
    >
      <StyledTypography variant="body2">{keyword.code}</StyledTypography>
    </KeywordBadgeWrapper>
  );
}
