const rem = (pxValue: number) => `${pxValue / 16}rem`;

const fontSize = (size: 1 | 2 | 3 | 4 | 5 | 6) => {
  switch (size) {
    case 1:
      return `
        font-size: ${rem(12)};
        line-height: ${rem(15)};
      `;
    case 2:
      return `
        font-size: ${rem(14)};
        line-height: ${rem(18)};
      `;
    case 4:
      return `
        font-size: ${rem(26)};
        line-height: ${rem(33)};
      `;
    case 5:
      return `
        font-size: ${rem(28)};
        line-height: ${rem(36)};
      `;
    case 6:
      return `
      font-size: ${rem(50)};
      line-height: ${rem(55)};
    `;
  }
};

export const lighten = (color: any, amount: string) =>
  `mix(white, ${color}, ${amount})`;

export const theme = {
  boldXLight: 200,
  boldLight: 300,
  boldRegular: 400,
  boldSemi: 600,
  boldBold: 700,
  boldBlack: 900,

  colorPrimary: "#ef486b",
  colorSecondary: "#2d3282",
  colorTertiary: "#637381",
  colorBorder: "#d3d8dd",
  colorDeactivated: "#919eab",
  colorLight: "#e5e5e5",
  colorLightBg: "#f7f8fa",
  colorBlack: "#454647",
  colorWhite: "#fff",
  colorBoxShadow: "#00000052",

  colorSuccess: "#28a745",
  colorWarning: "#ffc107",
  colorError: "#d60017",

  badgeLargeRed: "#e1637b",
  badgeLargeBlue: "#6c63ff",
  badgeLargeGreen: "#45b23b",
  badgeLargeYellow: "#ffc513",
  badgeLargeGray: "#b2b2b2",
  badgeLargeOrange: "#fb7500",

  badgeNormalRed: "#ef486b",
  badgeNormalBlue: "#1a7dbe",
  badgeNormalGreen: "#00c471",
  badgeNormalYellow: "#ffc513",
  badgeNormalGray: "#b2b2b2",
  badgeNormalOrange: "#ffa51e",

  lightBlue: "#dcdaff",
  lightRed: "#f8dadf",
  lightOrange: "#ffe9c9",
  lightYellow: "#fff1c7",
  lightGreen: "#d3edd0",
  lightGray: "#f6f6f7",

  alertColorSuccess: "#1e4620",
  alertColorInfo: "#014361",
  alertColorWarning: "#663c00",
  alertColorError: "#5f2120",
  alertColorHelp: "#495057",
  alertBackgroundColorSuccess: "#edf7ed",
  alertBackgroundColorInfo: "#e5f6fd",
  alertBackgroundColorWarning: "#fff4e5",
  alertBackgroundColorError: "#fdeded",
  alertBackgroundColorHelp: "#EBEDEF",
  alertIconColorSuccess: "#4caf4f",
  alertIconColorInfo: "#03a8f4",
  alertIconColorWarning: "#f90",
  alertIconColorError: "#ef5350",
  alertIconColorHelp: "#495057",

  gray100: "#f8f9fa",
  gray200: "#e9ecef",
  gray300: "#dee2e6",
  gray400: "#ced4da",
  gray500: "#adb5bd",
  gray600: "#868e96",
  gray700: "#495057",
  gray800: "#343a40",
  gray900: "#212529",

  borderWidth: "1px",

  fontFamilyTitle: `"Montserrat", regular`,
  fontFamilyText: `"Open Sans", regular`,

  boxShadow1: `0 0 ${rem(2)} #00000052`,
  boxShadow2: `0 ${rem(1)} ${rem(3)} #00000052`,
  boxShadow3: `${rem(1)} ${rem(1)} ${rem(6)} #00000052`,
  boxShadow4: `${rem(2)} ${rem(2)} ${rem(6)} #00000052`,

  headerHeight: rem(101),
  SProgressStepHeight: rem(32),

  rem,
  fontSize,
} as const;
