import { Reducer } from "redux";
import { Actions } from "./actions";

export type QueueState = {
  work: { try: number } & (
    | {
        status: "IDLE";
      }
    | {
        status: "READY";
        name: string;
        folder: string;
      }
    | {
        status: "UPLOADING";
        name: string;
        folder: string;
        progress: number;
      }
  );
  queue: Array<{ name: string; folder: string }>;
  folders: FolderState;
};

type FileStatus =
  /**
   * @description file has freshly been captured
   */
  | "CAPTURED"
  /**
   * @description file has been canceled by user action
   */
  | "CANCELED"
  /**
   * @description file has freshly been persisted and queued for upload
   */
  | "PERSISTED"
  /**
   * @description file has the worker focus and uploading has not started
   */
  | "ACTIVE"
  /**
   * @description file is ongoing upload
   */
  | "UPLOADING"
  /**
   * @description
   * the file could not be found in indexed DB when trying to process the job
   */
  | "NOT_FOUND"
  /**
   * @description file has finished uploading, not on worker focus
   */
  | "UPLOADED"
  /**
   * @description file has been deleted from local device persistence
   */
  | "ARCHIVED";
type FolderState = Record<string, Record<string, FileStatus>>;

const folderState =
  (status: FileStatus) =>
  (state: FolderState, item: { name: string; folder: string }): FolderState => {
    const { [item.folder]: folder = {} } = state;
    const { [item.name]: currentStatus } = folder;
    return currentStatus === status
      ? state
      : {
          ...state,
          [item.folder]: {
            ...folder,
            [item.name]: status,
          },
        };
  };

const idle = { status: "IDLE", try: 0 } as const;

const initialState: QueueState = {
  work: idle,
  queue: [],
  folders: {},
};

export const reducer: Reducer<QueueState, Actions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "REHYDRATION": {
      return action.queue.length > 0
        ? {
            ...state,
            queue: action.queue,
            folders: action.queue.reduce(
              folderState("PERSISTED"),
              state.folders
            ),
          }
        : state;
    }
    case "FILES_CREATED": {
      return {
        ...state,
        folders: action.files.reduce(folderState("CAPTURED"), state.folders),
      };
    }
    case "FILE_STORED": {
      return {
        ...state,
        folders: folderState("PERSISTED")(state.folders, action),
        queue: [...state.queue, { name: action.name, folder: action.folder }],
      };
    }
    case "WORKER_ACTIVE": {
      const [first, ...queue] = state.queue;
      if (first !== undefined && state.work.status === "IDLE") {
        return {
          ...state,
          work: {
            status: "READY",
            folder: first.folder,
            name: first.name,
            try: state.work.try,
          },
          queue,
          folders: folderState("ACTIVE")(state.folders, first),
        };
      }
      if (state.work.status === "UPLOADING") {
        return {
          ...state,
          work: {
            ...state.work,
            status: "READY",
          },
          folders: folderState("ACTIVE")(state.folders, state.work),
        };
      }
      return state;
    }
    case "FILE_NOTFOUND": {
      return {
        ...state,
        work: idle,
        folders: folderState("NOT_FOUND")(state.folders, action),
      };
    }
    case "UPLOAD_START":
      return {
        ...state,
        work: {
          status: "UPLOADING",
          folder: action.folder,
          name: action.name,
          progress: 0,
          try: state.work.try + 1,
        },
        folders: folderState("UPLOADING")(state.folders, action),
      };
    case "UPLOAD_PROGRESS":
      return {
        ...state,
        work: {
          status: "UPLOADING",
          folder: action.folder,
          name: action.name,
          progress: action.progress,
          try: state.work.try,
        },
      };
    case "UPLOAD_DONE": {
      return {
        ...state,
        work: idle,
        folders: folderState("UPLOADED")(state.folders, action),
      };
    }
    case "FILE_DELETED": {
      return {
        ...state,
        folders: folderState("ARCHIVED")(state.folders, action),
      };
    }
    case "FILE_CANCELED": {
      const queue = state.queue.filter(isNotItem(action));
      return {
        ...state,
        queue: queue.length !== state.queue.length ? queue : state.queue,
        work:
          state.work.status !== "IDLE" && isItem(action)(state.work)
            ? idle
            : state.work,
        folders: folderState("CANCELED")(state.folders, action),
      };
    }
    case "FILE_STORED_ERROR": {
      return state;
    }
  }
  return never(state, action);
};

function never<T>(state: T, action: never) {
  return state;
}

const isNotItem =
  (source: { name: string; folder: string }) =>
  (item: { name: string; folder: string }) =>
    source.name !== item.name || source.folder !== item.folder;

const isItem =
  (source: { name: string; folder: string }) =>
  (item: { name: string; folder: string }) =>
    source.name === item.name && source.folder === item.folder;
