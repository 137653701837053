import React, { useState } from "react";

import KeywordCategory, {
  keywordCategoryMinMax,
  KeywordsCategory,
  KeywordsType,
  KeywordType,
} from "./components/KeywordCategory/KeywordCategory";

export interface KeywordsSelectorProps {
  categories: KeywordsCategory[];
  keywords: KeywordsType;
  onChange: CallableFunction;
  selectedIds: number[];
  editable?: boolean;
  onStatusChange: CallableFunction;
}

export default function KeywordsSelector({
  keywords,
  categories,
  onChange,
  selectedIds,
  editable,
  onStatusChange,
}: KeywordsSelectorProps) {
  const [selectedKeywords, setSelectedKeywords] = useState(selectedIds);

  const keywordByType = keywords.reduce(
    (acc, keyword) => {
      if (acc[keyword.type])
        return { ...acc, [keyword.type]: [...acc[keyword.type], keyword] };

      return acc;
    },

    categories.reduce((acc, type) => ({ ...acc, [type]: [] }), {}) as any
  );

  const getNbKeywordSelectedByCategory = (
    selectedIds: number[],
    category: string
  ) => {
    const keywordsFromCategoryIds = keywordByType[category]
      .filter((keyword: any) => keyword.type === category)
      .map((keyword: any) => keyword.id);

    return selectedIds.reduce(
      (acc, id) => acc + (keywordsFromCategoryIds.includes(id) ? 1 : 0),
      0
    );
  };

  const isValid = (selectedIds: number[]) => {
    const validCategories = [];
    categories.forEach((category) => {
      const { min, max } = keywordCategoryMinMax[category];
      const nbSelected = getNbKeywordSelectedByCategory(selectedIds, category);

      if (nbSelected >= min && (!max || nbSelected <= max)) {
        validCategories.push(categories);
      }
    });

    return validCategories.length === categories.length;
  };

  const handleClick = (keyword: KeywordType) => (event: MouseEvent) => {
    event.preventDefault();
    const keywordId = keyword.id;
    const tempKeywords = selectedKeywords.includes(keywordId)
      ? selectedKeywords.filter((item) => item !== keywordId)
      : [...selectedKeywords, keywordId];

    setSelectedKeywords(tempKeywords);
    onChange(tempKeywords, isValid(tempKeywords));
    onStatusChange(isValid(tempKeywords));
  };

  return (
    <div className="container mt-3">
      {categories.map((category) => (
        <KeywordCategory
          key={category}
          keywordByType={keywordByType}
          category={category}
          {...keywordCategoryMinMax[category]}
          selected={selectedKeywords}
          onClick={handleClick}
          editable={!!editable}
        />
      ))}
    </div>
  );
}
