import { QueueState } from "./reducer";

export function workerAvailable(state: QueueState) {
  return state.work.status === "IDLE" && state.queue.length > 0;
}

export function currentWork(state: QueueState) {
  return state.work;
}

export function retries(state: QueueState) {
  return state.work.try;
}

export function isCurrentJob(name: string, folder: string) {
  return function (state: QueueState) {
    return (
      state.work.status !== "IDLE" &&
      state.work.name === name &&
      state.work.folder === folder
    );
  };
}
