import { createStore } from "redux";
import { reducer } from "./reducer";
export type { QueueState } from "./reducer";
export * as actionCreators from "./actions";
export type { Actions } from "./actions";

// eslint-disable-next-line import/no-anonymous-default-export
export default () =>
  createStore(
    reducer,
    (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
      (window as any).__REDUX_DEVTOOLS_EXTENSION__()
  );
