import React, { useState } from "react";
import { StepFC } from "./typings";
import { StepFormContainer } from "../StepFormContainer";
import { useTranslation } from "react-i18next";

import PrerequisitesSVG from "../../assets/prerequisites.svg";
import { Alert, AlertTitle, Box, Typography } from "@mui/material";
import { PrerequisiteCard } from "../PrerequisiteCard";
import { useNavigate } from "react-router-dom";
import { LazyPrerequisite, Visit } from "../../models";
import { DataStore } from "aws-amplify";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export const Prerequisite: StepFC = ({ visit, previous, next }) => {
  const navigate = useNavigate();
  const { origin, updated } = visit;
  const [prerequisite, setPrerequisite] = useState(
    updated?.apartment?.prerequisites ?? origin?.apartment?.prerequisites ?? []
  );
  const { t } = useTranslation("translation", {
    keyPrefix: "prerequisite",
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    return DataStore.save(
      Visit.copyOf(visit, (draft) => {
        if (!draft.updated) {
          draft.updated = {};
        }
        if (!draft.updated.steps) {
          draft.updated.steps = {};
        }
        if (!draft.updated.apartment) {
          draft.updated.apartment = {};
        }

        draft.updated.apartment.prerequisites = prerequisite;
        draft.updated.steps.prerequisites = true;
      })
    ).then(() => {
      navigate(`?step=${next}`, {
        relative: "path",
      });
    });
  };

  const handleChange = (event: LazyPrerequisite) => {
    setPrerequisite(
      prerequisite.map((pre) => {
        if (pre.type === event.type) {
          return event;
        }

        return pre;
      })
    );
  };

  const shouldDisabled = prerequisite.reduce((acc, prq) => {
    return acc || prq.status === null;
  }, false);

  return (
    <StepFormContainer
      title={t("title")}
      previous={previous}
      onSubmit={handleSubmit}
      disabled={shouldDisabled}
      visit={visit}
    >
      <Box
        sx={{
          marginY: 3,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img src={PrerequisitesSVG} alt="prerequisites" />
      </Box>
      <Typography mb={2} sx={{ fontWeight: "bold", textAlign: "center" }}>
        {t("prerequisite")}
      </Typography>
      <Alert
        severity="warning"
        icon={<InfoOutlinedIcon sx={{ color: "#536B80" }} />}
        sx={{
          "& .MuiAlert-icon": { alignItems: "center" },
          borderRadius: 3,
          marginBottom: 2,
          bgcolor: "#F2F2F2",
          color: "#536B80",
        }}
      >
        <AlertTitle sx={{ fontWeight: 600, fontSize: "0.9rem" }}>
          {t("alert.title")}
        </AlertTitle>
        <Typography variant="caption" sx={{ letterSpacing: 0, lineHeight: 0 }}>
          {t("alert.subtitle")}
        </Typography>
      </Alert>
      <Box m={1} borderRadius={5}>
        {prerequisite?.map((pre, index) => (
          <PrerequisiteCard
            handleChange={handleChange}
            key={`${index}_${pre.type}`}
            prerequisite={pre}
          />
        ))}
      </Box>
    </StepFormContainer>
  );
};
