import { DataStore, type DataStoreSnapshot } from "@aws-amplify/datastore";
import { Visit } from "../models";
import {
  useEffect,
  useState,
  createContext,
  createElement,
  useContext,
  useMemo,
} from "react";
import { useParams } from "react-router-dom";
import moment from "moment-timezone";

const _useVisit = () => {
  const [data, setData] = useState<DataStoreSnapshot<Visit>>();

  useEffect(() => {
    const subscription = DataStore.observeQuery(Visit, (v) =>
      v.or((v) => [
        v.scheduledDate.gt(moment().startOf("day").toISOString()),
        v.complete.eq(true),
      ])
    ).subscribe((dt) => {
      setData(dt);
    });
    return () => subscription.unsubscribe();
  }, [setData]);
  return data;
};

const VisitContext = createContext<DataStoreSnapshot<Visit> | undefined>(
  undefined
);

export const VisitsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => createElement(VisitContext.Provider, { value: _useVisit() }, children);

export const useVisits = () => useContext(VisitContext);

export const useCurrentVisit = () => {
  const { visitId } = useParams();
  const visits = useVisits();

  return useMemo(() => {
    return visits ? visits.items.find(({ id }) => visitId === id) : undefined;
  }, [visits, visitId]);
};
