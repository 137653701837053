import { styled } from "@mui/material";

export const AccessCreationWrapper = styled("div")`
  ${({ theme }) => ({
    "& .MuiFormControl-root": {
      margin: `${theme.spacing(0.5)} 0`,
    },
    "& .MuiInputBase-root::before": {
      borderBottom: `solid 1px ${theme.colorSecondary}`,
    },
    "& .MuiInputBase-root::hover": {
      borderBottom: `solid 1px ${theme.colorSecondary}`,
    },
    "& .MuiInputBase-root::after": {
      borderBottom: `solid 2px ${theme.colorSecondary}`,
    },
    "& .MuiSvgIcon-root": {
      color: theme.colorSecondary,
    },
  })}

  .AccessCreation {
    &__title {
      position: relative;
      top: ${({ theme }) => theme.rem(10)};
      left: ${({ theme }) => theme.rem(30)};
      width: fit-content;
      padding: 0 ${({ theme }) => theme.rem(10)};
      color: ${({ theme }) => theme.colorSecondary};
      background-color: ${({ theme }) => theme.colorWhite};
    }

    &__hide-typography {
      position: absolute;
      overflow: hidden;
      white-space: pre;
      visibility: hidden;
    }

    .modal-body {
      display: flex;
      min-height: 50vh;
      padding: 0;
    }

    &__editzone {
      $ez: &;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding: ${({ theme }) => theme.rem(20)};
      padding-top: ${({ theme }) => theme.rem(0.5)};

      &__loop {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        color: ${({ theme }) => theme.colorBlack};
      }
    }

    &__button {
      ${({ theme }) => ({
        "& .MuiSvgIcon-root": {
          display: "none",
        },
        "& .MuiSelect-select": {
          padding: `${theme.spacing(0.5)} ${theme.spacing(2)} !important`,
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.colorSecondary,
          borderRadius: theme.spacing(1),
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: theme.colorSecondary,
        },
      })}
    }

    &__tipszone {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 30%;
      margin: ${({ theme }) => theme.rem(-20)};
      margin-left: unset;
      background-color: lighten(${({ theme }) => theme.colorSecondary}, 30);
      border-top-right-radius: ${({ theme }) => theme.rem(12)};
      border-bottom-right-radius: ${({ theme }) => theme.rem(12)};
    }
  }
`;
