import { styled } from "@mui/material";

export const EquipmentWrapper = styled("button")`
  &.Equipment {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    font-weight: 600;
    color: ${({ theme }) => theme.colorTertiary};
    text-align: center;
    white-space: nowrap;
    background: transparent;
    border: ${({ theme }) => `${theme.borderWidth} solid ${theme.gray500}`};
    border-radius: 0.8rem;
    opacity: 0.8;

    &--disabled {
      cursor: not-allowed;
    }

    &--checked {
      background: #fbd5dd;
      border: ${({ theme }) =>
        `${theme.borderWidth} solid ${theme.colorPrimary}`};
    }

    &:focus {
      outline: none;
    }
  }

  .MuiTypography-root {
    color: #a7a7a7;
    text-align: center;
    white-space: normal;
    line-height: 1;
    font-size: ${({ theme }) => theme.rem(14)};
    margin-bottom: 1rem;
  }

  .Equipment__input-checkbox {
    position: absolute;
    height: ${({ theme }) => theme.rem(110)};
    width: ${({ theme }) => theme.rem(110)};
    border: ${({ theme }) => `${theme.borderWidth} solid ${theme.gray500}`};
    border-radius: 0.8rem;
    margin-top: -${({ theme }) => theme.rem(5)};
    cursor: pointer;
    opacity: 0;

    &--disabled {
      cursor: not-allowed;
    }
  }

  input[type="checkbox"] {
    margin: unset;
  }

  .Equipment__input-radio {
    position: relative;
    top: ${({ theme }) => theme.rem(6)};
    width: ${({ theme }) => theme.rem(16)};
    height: ${({ theme }) => theme.rem(16)};
    background-color: ${({ theme }) => theme.colorWhite};
    border: ${({ theme }) => `${theme.borderWidth} solid ${theme.colorBlack}`};
    border-radius: 50%;
    flex-shrink: 0;

    &--disabled {
      cursor: not-allowed;
      background: ${({ theme }) => theme.colorLight};
    }

    &--checked {
      position: absolute;
      top: ${({ theme }) => -theme.rem(7)};
      left: ${({ theme }) => -theme.rem(7)};
      height: ${({ theme }) => theme.rem(30)};
      transform: scale(0.7);

      @media only screen and (min-resolution: 144dpi),
        (min-resolution: 1.5dppx) {
        background-size: 130px 700px;
      }
    }
  }
`;
