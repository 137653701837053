import { Visit, Steps, LazySteps } from "../../models";
import { StepValues } from "./typings";

export const stepOrder: (keyof Steps)[] = [
  "client",
  "apartment",
  "rooms",
  "equipments",
  "prerequisites",
  "tags",
  "businessOffer",
  "insurance",
  "logistics",
  "photos",
  "access",
  "next",
  "recap",
];

function areAllFieldsTruthy(obj: LazySteps): boolean {
  return Object.values(obj).every((value) => !!value);
}

export const getStep = (visit: Visit) => {
  if (visit.updated?.steps && areAllFieldsTruthy(visit.updated?.steps)) {
    return "recap";
  }

  let step = stepOrder.find(
    (step) => !visit.updated?.steps || visit.updated?.steps[step] !== true
  );

  if (!step) {
    step = "client";
  }

  return step;
};

export const getNextStep = (current: StepValues) => {
  const index = stepOrder.indexOf(current);
  return index === -1
    ? null
    : index === stepOrder.length - 1
    ? null
    : stepOrder[index + 1];
};

export const getPreviousStep = (current: StepValues) => {
  const index = stepOrder.indexOf(current);
  return index <= 0 ? null : stepOrder[index - 1];
};
