import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { Auth } from "aws-amplify";
import { useEffect } from "react";

export const useAuthenticate = () => {
  useEffect(() => {
    Auth.currentAuthenticatedUser().catch(() =>
      Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Google,
      })
    );
  }, []);
};
