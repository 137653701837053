import { styled } from "@mui/material";

export const KeywordCategoryWrapper = styled("div")`
  .KeywordCategory {
    &__container {
      display: flex;
      flex-direction: column;
      width: 100%;
    }

    &__header {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 0.6rem;
    }

    &__min-max {
      margin-left: 0.5rem;
      font-size: 0.8rem;
      visibility: hidden;
    }

    &__keyword-list {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: ${({ theme }) => theme.spacing(2)};
    }

    &__header--edit {
      .KeywordCategory {
        &__min-max {
          visibility: visible;
        }
      }
    }
  }
`;
