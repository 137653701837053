import React from "react";
import { StepFC } from "./typings";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import RecapSVG from "../../assets/recap.svg";

import { Box, TextField } from "@mui/material";
import { ApartmentData, LazyApartmentData, Visit } from "../../models";
import { DataStore } from "aws-amplify";
import { StepFormContainer } from "../StepFormContainer";
import { useAuthenticator } from "@aws-amplify/ui-react";

export const Recap: StepFC = ({ visit, previous, current }) => {
  const { origin, updated } = visit;
  const navigate = useNavigate();
  const { user } = useAuthenticator();

  const { t } = useTranslation("translation", {
    keyPrefix: "recap",
  });

  const submitVisit = () => {
    const lambdaURL = process.env.REACT_APP_LAMBDA_SUBMIT_VISITS_URL as string;
    const lambdaKey = process.env.REACT_APP_LAMBDA_SUBMIT_VISITS_KEY as string;

    return fetch(lambdaURL, {
      mode: "cors",
      method: "POST",
      headers: {
        "x-api-key": lambdaKey,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        visitId: visit.id,
      }),
    }).then((res) => res.json().then(console.log));
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    await DataStore.save(
      Visit.copyOf(visit, (draft) => {
        const data = new FormData(event.target as HTMLFormElement);
        const inputValue: LazyApartmentData = Object.fromEntries(
          data.entries()
        );

        if (!draft.updated) {
          draft.updated = {};
        }
        if (!draft.updated.steps) {
          draft.updated.steps = {};
        }
        if (!draft.updated.apartment) {
          draft.updated.apartment = {};
        }

        draft.updated.apartment.visitGlobalInformation =
          inputValue.visitGlobalInformation;
        draft.updated.apartment.visitAttentionPoints =
          inputValue.visitAttentionPoints;
        draft.updated.steps.recap = true;
        draft.complete = true;

        if (user.attributes?.email) draft.agents = [user.attributes?.email];
      })
    );

    setTimeout(() => submitVisit(), 1000); // Delay the lambda triggering to be sure that the graphql mutations are properly propagated

    navigate("", {
      relative: "path",
    });
  };

  const extractValue = <T extends keyof ApartmentData>(
    key: T
  ): ApartmentData[T] => {
    return updated?.apartment?.[key] ?? origin?.apartment?.[key];
  };

  const defaultValues = {
    visitGlobalInformation: extractValue("visitGlobalInformation"),
    visitAttentionPoints: extractValue("visitAttentionPoints"),
  };

  return (
    <StepFormContainer
      title={t("title")}
      previous={previous}
      onSubmit={handleSubmit}
      isLastStep
      visit={visit}
    >
      <Box sx={{ marginY: 5, display: "flex", justifyContent: "center" }}>
        <img src={RecapSVG} alt="recap-svg" />
      </Box>
      <TextField
        name="visitGlobalInformation"
        label={t("visitGlobalInformation.label")}
        multiline
        rows={3}
        placeholder={t("visitGlobalInformation.placeholder") || ""}
        InputLabelProps={{ shrink: true }}
        defaultValue={defaultValues.visitGlobalInformation}
        sx={{ marginBottom: 3 }}
        required
      />
      <TextField
        name="visitAttentionPoints"
        label={t("visitAttentionPoints.label")}
        multiline
        rows={3}
        placeholder={t("visitAttentionPoints.placeholder") || ""}
        InputLabelProps={{ shrink: true }}
        defaultValue={defaultValues.visitAttentionPoints}
        sx={{ marginBottom: 3 }}
      />
    </StepFormContainer>
  );
};
