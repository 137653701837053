import { DataStore } from "aws-amplify";
import { ApartmentData, CategoryEnum, HousingEnum, Visit } from "../../models";
import { useNavigate } from "react-router-dom";
import { StepFC } from "./typings";
import { StepFormContainer } from "../StepFormContainer";
import { useTranslation } from "react-i18next";
import {
  FormControlLabel,
  FormLabel,
  RadioGroup,
  TextField,
  Radio,
  InputAdornment,
  Autocomplete,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { ChangeEvent, useState } from "react";

export const ApartmentInformations: StepFC = ({ visit, previous, next }) => {
  const { origin, updated } = visit;
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("translation", {
    keyPrefix: "apartmentInformations",
  });

  const extractValue = <T extends keyof ApartmentData>(
    key: T
  ): ApartmentData[T] => {
    return updated?.apartment?.[key] ?? origin?.apartment?.[key];
  };

  const [elevator, setElevator] = useState(
    updated?.apartment?.elevator ?? origin?.apartment.elevator
  );
  const [housingType, setHousingType] = useState(
    updated?.apartment?.housingType ?? origin?.apartment.housingType
  );
  const [category, setCategory] = useState(
    updated?.apartment?.category ?? origin?.apartment.category
  );

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    return DataStore.save(
      Visit.copyOf(visit, (draft) => {
        const inputs = event.currentTarget.elements;
        if (!draft.updated) {
          draft.updated = {};
        }
        if (!draft.updated.steps) {
          draft.updated.steps = {};
        }
        if (!draft.updated.apartment) {
          draft.updated.apartment = {};
        }

        draft.updated.steps.apartment = true;
        draft.updated.apartment.address = (inputs as any).address.value;
        draft.updated.apartment.city = (inputs as any).city.value;
        draft.updated.apartment.postalCode = (inputs as any).postalCode.value;
        draft.updated.apartment.area = Number((inputs as any).area.value);
        draft.updated.apartment.floor = (inputs as any).floor.value;
        draft.updated.apartment.door = (inputs as any).door.value;
        draft.updated.apartment.risk = Number((inputs as any).risk.value);
        draft.updated.apartment.housingType = housingType;
        draft.updated.apartment.elevator = elevator;
        draft.updated.apartment.category = category;
        draft.updated.apartment.capacity = Number(
          (inputs as any).capacity.value
        );

        if (elevator) {
          draft.updated.apartment.equipments = ["elevator"];
        }
      })
    ).then(() =>
      navigate(`?step=${next}`, {
        relative: "path",
      })
    );
  };

  const defaultValue = {
    address: extractValue("address"),
    postalCode: extractValue("postalCode"),
    city: extractValue("city"),
    area: extractValue("area"),
    floor: extractValue("floor"),
    door: extractValue("door"),
    capacity: extractValue("capacity"),
    risk: extractValue("risk"),
  };

  const handleElevator = (event: ChangeEvent<HTMLInputElement>) => {
    setElevator(event.target.value === "true");
  };

  const handleHousingType = (event: ChangeEvent<HTMLInputElement>) => {
    setHousingType(event.target.value as HousingEnum);
  };

  return (
    <StepFormContainer
      previous={previous}
      onSubmit={handleSubmit}
      title={t("title")}
      visit={visit}
    >
      <TextField
        name="address"
        label={t("address")}
        defaultValue={defaultValue.address}
        required
      />
      <TextField
        name="postalCode"
        label={t("postalCode")}
        defaultValue={defaultValue.postalCode}
        required
      />
      <TextField
        name="city"
        label={t("city")}
        defaultValue={defaultValue.city}
        required
      />
      <FormLabel focused={false} id="housing" sx={{ fontSize: "0.75rem" }}>
        {t("housingType")}
      </FormLabel>
      <RadioGroup
        row
        aria-labelledby="housing"
        name="housing"
        value={housingType}
        onChange={handleHousingType}
      >
        <FormControlLabel
          value={HousingEnum["APARTMENT"]}
          label={t("housingTypes.apartment")}
          control={<Radio />}
        />
        <FormControlLabel
          value={HousingEnum["HOUSE"]}
          label={t("housingTypes.house")}
          control={<Radio />}
        />
      </RadioGroup>
      <FormControl>
        <InputLabel id="category" required>
          {t("category")}
        </InputLabel>
        <Select
          name="category"
          value={category || ""}
          label={t("category")}
          onChange={(event) => setCategory(event.target.value as CategoryEnum)}
          required
        >
          {Object.keys(CategoryEnum).map((category) => (
            <MenuItem key={category} value={category}>
              {t(`categories.${category}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        name="area"
        label={t("area")}
        defaultValue={defaultValue.area}
        type="number"
        InputProps={{
          endAdornment: <InputAdornment position="end">m²</InputAdornment>,
        }}
        required
      />
      <TextField
        name="floor"
        label={t("floor")}
        defaultValue={defaultValue.floor}
        required
      />
      <TextField
        name="door"
        label={t("door")}
        defaultValue={defaultValue.door}
      />
      <FormLabel focused={false} id="elevator" sx={{ fontSize: "0.75rem" }}>
        {t("elevator")}
      </FormLabel>
      <RadioGroup
        row
        aria-labelledby="elevator"
        name="elevator"
        value={elevator}
        onChange={handleElevator}
      >
        <FormControlLabel
          value={true}
          label={i18n.t("common.yes")}
          control={<Radio />}
        />
        <FormControlLabel
          value={false}
          label={i18n.t("common.no")}
          control={<Radio />}
        />
      </RadioGroup>
      <TextField
        name="capacity"
        label={t("capacity")}
        defaultValue={defaultValue.capacity}
        type="number"
        inputProps={{
          pattern: "\\d*",
        }}
        required
      />
      <Autocomplete
        id="risk"
        freeSolo
        options={[500, 800, 1500]}
        getOptionLabel={(option) => (option || "").toString()}
        defaultValue={defaultValue.risk}
        renderInput={(params) => (
          <TextField
            aria-labelledby="risk"
            {...params}
            inputProps={{
              ...params.inputProps,
              pattern: "\\d*",
            }}
            name="risk"
            type="number"
            label={t("risk")}
            required
          />
        )}
      />
    </StepFormContainer>
  );
};
